define("riot/components/header-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['header-bar'],
    classNameBindings: ['editable'],
    focusOut: function focusOut() {
      if (this.editable && this.action) {
        var value = this.element.querySelector('h2').innerText;
        this.action(value);
      }
    }
  });

  _exports.default = _default;
});