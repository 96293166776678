define("riot/templates/components/smart-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cX4hPn8R",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[1,[30,[36,4],null,[[\"color\",\"label\",\"icon\",\"iconPosition\",\"disabled\"],[\"gray\",[35,3,[\"title\"]],\"angle-down\",\"right\",[35,2]]]]],[10,\"ul\"],[14,0,\"options\"],[12],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[11,\"li\"],[16,0,[30,[36,0],[[32,1,[\"selected\"]],\"selected\"],null]],[4,[38,1],[[32,0],\"optionSelected\",[32,1,[\"value\"]]],[[\"on\"],[\"click\"]]],[12],[1,[32,1,[\"title\"]]],[13]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"disabled\",\"optionSelected\",\"smart-button\",\"transformedOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "riot/templates/components/smart-dropdown.hbs"
    }
  });

  _exports.default = _default;
});