define("riot/templates/components/campaign-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2HxwSpeQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"section\"],[14,0,\"heading\"],[12],[10,\"div\"],[14,0,\"validation\"],[12],[1,[30,[36,4],null,[[\"icon\",\"size\"],[[35,5],6]]]],[6,[37,6],[[35,1]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,4],null,[[\"icon\",\"size\"],[\"check\",3]]]]],\"parameters\":[]}]]],[13],[11,\"div\"],[24,0,\"main\"],[4,[38,0],[[32,0],\"expand\"],[[\"on\"],[\"click\"]]],[12],[10,\"h4\"],[12],[1,[34,7]],[13],[10,\"p\"],[12],[1,[34,8]],[13],[13],[10,\"div\"],[14,0,\"actions\"],[12],[6,[37,6],[[35,9]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,3],null,[[\"color\",\"label\",\"disabled\",\"action\"],[\"purple\",\"Save\",[30,[36,2],[[35,1]],null],[30,[36,0],[[32,0],\"save\"],null]]]]]],\"parameters\":[]}]]],[13],[13],[10,\"div\"],[14,0,\"yield\"],[12],[18,1,null],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"isValid\",\"not\",\"smart-button\",\"hero-icon\",\"icon\",\"if\",\"title\",\"description\",\"isExpanded\"]}",
    "meta": {
      "moduleName": "riot/templates/components/campaign-setting.hbs"
    }
  });

  _exports.default = _default;
});