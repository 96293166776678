define("riot/components/training-preview", ["exports", "riot/chats/training/phishing"], function (_exports, _phishing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['training-preview'],
    classNameBindings: ['window:with-window'],
    currentUser: Ember.inject.service(),
    api: Ember.inject.service(),
    upcoming: [],
    window: false,
    // eslint-disable-next-line ember/no-on-calls-in-components
    setMessages: Ember.on('init', function () {
      var count = this.window ? 10 : 100;

      var messages = _phishing.default.slice(1, count);

      this.set('messages', messages);
    })
  });

  _exports.default = _default;
});