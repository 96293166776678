define("riot/helpers/and", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    return params.reduce(function (acc, param) {
      return acc && !!param;
    }, true);
  });

  _exports.default = _default;
});