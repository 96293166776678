define("riot/routes/groups/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('name', controller.keyword || '');
      controller.set('isLoading', false);

      if (controller.employees) {
        controller.set('employeesArray', controller.employees.split(','));
      } else {
        controller.set('employeesArray', []);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.set('keyword', null);
      }
    }
  });

  _exports.default = _default;
});