define("riot/services/ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    notify: Ember.inject.service(),
    handleResponse: function handleResponse(status, headers, payload
    /*, requestData*/
    ) {
      if (status !== 200 && payload.message) {
        this.notify.error(payload);
        return false;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});