define("riot/components/radio-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['radio-input'],
    classNameBindings: ['disabled'],
    disabled: false,
    watch: Ember.on('didInsertElement', function () {
      var _this = this;

      this.$('input').change(function () {
        _this.checked(_this.value);
      });
    })
  });

  _exports.default = _default;
});