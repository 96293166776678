define("riot/router", ["exports", "riot/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('signup');
    this.route('welcome', function () {
      this.route('about-you');
      this.route('about-your-company');
      this.route('domain-used');
    });
    this.route('logout');
    this.route('setup');
    this.route('ready');
    this.route('phishing', {
      path: '/r/:attack'
    }, function () {
      this.route('adp');
      this.route('atlassian');
      this.route('aws');
      this.route('default');
      this.route('dropbox');
      this.route('google');
      this.route('github');
      this.route('icloud');
      this.route('linkedin');
      this.route('microsoft');
      this.route('netflix');
      this.route('1password');
      this.route('outlook');
      this.route('paypal');
      this.route('quickbooks');
      this.route('salesforce');
      this.route('slack');
      this.route('uber');
      this.route('workday');
      this.route('zendesk');
    });
    this.route('attacks');
    this.route('attack', {
      path: '/attack/:id'
    });
    this.route('train', {
      path: '/train/:id'
    });
    this.route('campaigns', function () {
      this.route('new');
    });
    this.route('groups', function () {
      this.route('new');
    });
    this.route('employees', function () {
      this.route('import');
      this.route('new');
    });
    this.route('employee', {
      path: '/employee/:id'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('edit');
      this.route('attack');
    });
    this.route('group', {
      path: '/group/:id'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('edit');
    });
    this.route('campaign', {
      path: '/campaign/:id'
    }, function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('audience');
      });
      this.route('edit');
    });
    this.route('settings');
    this.route('billing');
    this.route('404', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});