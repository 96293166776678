define("riot/templates/components/tags-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GUuom88d",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"ul\"],[12],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"li\"],[14,0,\"badge gray\"],[12],[1,[32,1,[\"value\"]]],[13]],\"parameters\":[1]}]]],[13],[1,[30,[36,4],null,[[\"value\"],[[35,3]]]]]],\"hasEval\":false,\"upvars\":[\"items\",\"-track-array\",\"each\",\"value\",\"input\"]}",
    "meta": {
      "moduleName": "riot/templates/components/tags-input.hbs"
    }
  });

  _exports.default = _default;
});