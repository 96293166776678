define("riot/components/launch-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    audience: Ember.computed.alias('campaign.audience'),
    actions: {
      changeStatus: function changeStatus(status) {
        this.changeStatus(status);
      },
      closeModal: function closeModal() {
        this.closeModal();
      }
    }
  });

  _exports.default = _default;
});