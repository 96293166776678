define("riot/templates/components/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v8wu8jrs",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"label\",\"for\",\"value\",\"click\",\"action\"],[[32,1,[\"name\"]],[35,3],[30,[36,2],[[35,1],[32,1,[\"key\"]]],null],[30,[36,0],[[32,0],\"clicked\",[32,1]],null],[30,[36,0],[[32,0],\"valueChanged\",[32,1,[\"key\"]]],null]]],[[\"default\"],[{\"statements\":[[18,2,[[32,1]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"transformer\",\"get\",\"for\",\"checkbox-input\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "riot/templates/components/checkbox-group.hbs"
    }
  });

  _exports.default = _default;
});