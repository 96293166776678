define("riot/components/hero-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['hero-icon'],
    classNameBindings: ['$icon', 'color', '$size'],
    size: 4,
    $type: Ember.computed('icon', 'type', function () {
      return this.type || this.icon.split(':').shift() || 'outline';
    }),
    $size: Ember.computed('size', function () {
      return "size-".concat(this.size);
    }),
    $icon: Ember.computed('icon', function () {
      return this.icon.split(':').pop();
    })
  });

  _exports.default = _default;
});