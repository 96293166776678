define("riot/components/smart-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['smart-button'],
    classNameBindings: ['color', 'loading', 'small', 'iconPositionClass', 'icon:has-icon', 'disabled'],
    attributeBindings: ['type', 'disabled'],
    type: 'submit',
    // Attributes
    icon: null,
    iconPosition: 'left',
    label: 'Click',
    loading: false,
    disabled: false,
    iconPositionClass: Ember.computed('iconPosition', function () {
      return "icon-".concat(this.iconPosition);
    }),
    click: function click() {
      if (this.action && !this.disabled) {
        this.action();
      }
    }
  });

  _exports.default = _default;
});