define("riot/controllers/campaign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    campaign: Ember.computed.alias('model'),
    tags: Ember.computed('campaign.{status,statusColor}', function () {
      return [{
        label: this.get('campaign.status').capitalize(),
        color: this.get('campaign.statusColor')
      }];
    })
  });

  _exports.default = _default;
});