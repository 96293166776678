define("riot/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ewyJu4Na",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading-page\"],[12],[10,\"div\"],[14,0,\"loader\"],[12],[2,\"Please wait…\"],[13],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "riot/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});