define("riot/templates/components/toggle-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QMgdKhLG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[10,\"div\"],[14,0,\"toggle\"],[12],[10,\"span\"],[12],[10,\"div\"],[14,0,\"on\"],[12],[1,[30,[36,0],null,[[\"icon\"],[\"solid:check\"]]]],[13],[10,\"div\"],[14,0,\"off\"],[12],[1,[30,[36,0],null,[[\"icon\"],[\"solid:x\"]]]],[13],[13],[13],[10,\"strong\"],[12],[1,[34,1]],[13],[13],[18,1,null]],\"hasEval\":false,\"upvars\":[\"hero-icon\",\"label\"]}",
    "meta": {
      "moduleName": "riot/templates/components/toggle-input.hbs"
    }
  });

  _exports.default = _default;
});