define("riot/templates/components/select-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z8RNs6Zo",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[11,\"select\"],[16,\"disabled\",[34,0]],[4,[38,1],[[32,0],\"selectionChanged\"],[[\"on\"],[\"change\"]]],[12],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"option\"],[15,2,[32,1,[\"key\"]]],[12],[1,[32,1,[\"value\"]]],[13]],\"parameters\":[1]}]]],[13],[18,2,null]],\"hasEval\":false,\"upvars\":[\"disabled\",\"action\",\"formattedOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "riot/templates/components/select-input.hbs"
    }
  });

  _exports.default = _default;
});