define("riot/helpers/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uppercase = uppercase;
  _exports.default = void 0;

  function uppercase(params, attrs) {
    var value = params[0] || attrs.value || '';
    return value.toString().toUpperCase();
  }

  var _default = Ember.Helper.helper(uppercase);

  _exports.default = _default;
});