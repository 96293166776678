define("riot/templates/employee/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FPQPFWTi",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],null,[[\"close\"],[[30,[36,0],[[32,0],\"close\"],null]]],[[\"default\"],[{\"statements\":[[11,\"form\"],[4,[38,0],[[32,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[10,\"section\"],[12],[10,\"h3\"],[12],[2,\"Edit \"],[1,[35,1,[\"name\"]]],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Name\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,2],null,[[\"value\"],[[35,1,[\"name\"]]]]]],[13],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Email address\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,2],null,[[\"disabled\",\"value\"],[true,[35,1,[\"email\"]]]]]],[13],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Health\"],[13],[1,[30,[36,4],null,[[\"value\",\"options\"],[[35,1,[\"health\"]],[35,3]]]]],[13],[10,\"label\"],[14,\"for\",\"\"],[12],[10,\"span\"],[12],[2,\"Groups\"],[13],[1,[30,[36,6],null,[[\"resource\",\"value\",\"action\"],[\"group\",[35,5],[30,[36,0],[[32,0],\"groupsChanged\"],null]]]]],[13],[13],[10,\"section\"],[14,0,\"actions\"],[12],[10,\"div\"],[14,0,\"left\"],[12],[1,[30,[36,8],null,[[\"color\",\"type\",\"label\",\"action\",\"loading\"],[\"red\",\"button\",\"Delete\",[30,[36,0],[[32,0],\"delete\"],null],[35,7]]]]],[13],[1,[30,[36,8],null,[[\"color\",\"label\",\"loading\"],[\"purple\",\"Save\",[35,7]]]]],[13],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"employee\",\"input\",\"healths\",\"select-input\",\"groups\",\"array-input\",\"isLoading\",\"smart-button\",\"slide-over\"]}",
    "meta": {
      "moduleName": "riot/templates/employee/edit.hbs"
    }
  });

  _exports.default = _default;
});