define("riot/templates/components/on-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FhTTKWbb",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[10,\"ul\"],[12],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,2],null,[[\"message\",\"nextAction\",\"currentUser\"],[[32,1],[30,[36,1],[[32,0],\"next\"],null],[35,0]]]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"action\",\"chat-message\",\"messages\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "riot/templates/components/on-chat.hbs"
    }
  });

  _exports.default = _default;
});