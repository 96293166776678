define("riot/components/callout-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['callout-message'],
    classNameBindings: ['color', 'icon:icon']
  });

  _exports.default = _default;
});