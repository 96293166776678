define("riot/helpers/hours", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.date = date;
  _exports.default = void 0;

  function date(_ref, params) {
    var _ref2 = (0, _toArray2.default)(_ref),
        date = _ref2[0],
        args = _ref2.slice(1);

    if (params.date) {
      date = params.date;
    }

    var hours = date.getHours().toString();
    var minutes = date.getMinutes().toString();

    if (hours.length === 1) {
      hours = "0".concat(hours);
    }

    if (minutes.length === 1) {
      minutes = "0".concat(minutes);
    }

    return "".concat(hours, ":").concat(minutes);
  }

  var _default = Ember.Helper.helper(date);

  _exports.default = _default;
});