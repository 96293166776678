define("riot/models/attack", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // read only
    createdAt: (0, _model.attr)('timestamp'),
    phishedAt: (0, _model.attr)('timestamp'),
    creator: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    // part of the company employees
    company: (0, _model.belongsTo)('company'),
    employee: (0, _model.belongsTo)('employee'),
    campaign: (0, _model.belongsTo)('campaign'),
    // write only default values
    status: (0, _model.attr)('string', {
      defaultValue: 'incoming'
    }),
    // related
    activities: (0, _model.hasMany)('activity'),
    emailSettings: (0, _model.attr)(),
    isIncoming: Ember.computed.equal('status', 'incoming'),
    template: Ember.computed('emailSettings.template', 'store', function () {
      if (this.get('emailSettings.template')) {
        return this.store.findRecord('template', this.get('emailSettings.template'));
      }

      return null;
    }),
    templateName: Ember.computed('template.name', 'emailSettings.service', function () {
      var templateName = '';

      if (this.get('emailSettings.service') && this.get('emailSettings.service') !== 'plainText') {
        templateName = "".concat(this.get('emailSettings.service').capitalize(), ": ");
      }

      templateName += this.get('template.name');
      return templateName;
    })
  });

  _exports.default = _default;
});