define("riot/components/array-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['array-input', 'input'],
    tagName: 'dl',
    store: Ember.inject.service(),
    editable: true,
    hasMore: false,
    items: Ember.computed.map('value', ['resource'], function (item) {
      return this.store.findRecord(this.resource, item);
    }),
    actions: {
      add: function add(item) {
        var id = item.objectID;

        if (!this.value.includes(id)) {
          this.value.addObject(id);

          if (this.action) {
            this.action('add', id);
          }
        }
      },
      remove: function remove(item) {
        var id = item.get('id');
        this.value.removeObject(id);

        if (this.action) {
          this.action('remove', id);
        }
      },
      loadMore: function loadMore() {
        if (this.loadMore) {
          this.loadMore(this.items.lastObject);
        }
      }
    }
  });

  _exports.default = _default;
});