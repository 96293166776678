define("riot/components/outlook-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['action-panel', 'with-ol', 'outlook-action-panel'],
    tagName: 'main',
    currentUser: Ember.inject.service(),
    microsoftAdminLink: Ember.computed('currentUser.company.{domain,provider}', function () {
      return "https://outlook.office365.com/ecp/?rfr=Admin_o365&exsvurl=1&mkt=en-US&Realm=".concat(this.get('currentUser.company.domain'));
    }),
    actions: {
      tryOut: function tryOut() {
        this.tryOut();
      }
    }
  });

  _exports.default = _default;
});