define("riot/templates/components/dashboard-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "imMfgppl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"metrics\"],[12],[10,\"li\"],[14,0,\"main-metric\"],[12],[10,\"div\"],[14,0,\"metric\"],[12],[10,\"p\"],[12],[2,\"Currently\"],[13],[6,[37,3],[[30,[36,2],[[35,0],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"strong\"],[14,0,\"large success\"],[12],[2,\"No employee at risk\"],[13]],\"parameters\":[]},{\"statements\":[[10,\"strong\"],[14,0,\"large primary\"],[12],[1,[34,0]],[1,[30,[36,1],[[35,0],\" employee\"],null]],[2,\" at risk \"],[13]],\"parameters\":[]}]]],[13],[13],[10,\"li\"],[14,0,\"aside\"],[12],[10,\"div\"],[14,0,\"metric\"],[12],[10,\"p\"],[12],[2,\"Employees\"],[13],[10,\"div\"],[12],[10,\"strong\"],[14,0,\"large\"],[12],[1,[34,4]],[13],[10,\"div\"],[14,0,\"icon\"],[12],[1,[30,[36,5],null,[[\"icon\"],[\"information-circle\"]]]],[10,\"div\"],[14,0,\"tooltip\"],[12],[2,\"Tested every \"],[10,\"strong\"],[12],[1,[34,6]],[2,\" days\"],[13],[2,\" on average\"],[13],[13],[13],[13],[13],[13],[1,[30,[36,8],null,[[\"value\",\"percentage\"],[[35,7,[\"company\",\"statistics\"]],false]]]]],\"hasEval\":false,\"upvars\":[\"nbEmployeesAtRisk\",\"pluralize\",\"is-eq\",\"if\",\"nbEmployees\",\"hero-icon\",\"averageTest\",\"currentUser\",\"statistics-waterfall\"]}",
    "meta": {
      "moduleName": "riot/templates/components/dashboard-statistics.hbs"
    }
  });

  _exports.default = _default;
});