define("riot/utils/companySizes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COMPANY_SIZE = [{
    key: 'xs',
    value: '1 - 50'
  }, {
    key: 's',
    value: '51 - 250'
  }, {
    key: 'm',
    value: '251 - 1000'
  }, {
    key: 'l',
    value: '1001 - 5000'
  }, {
    key: 'xl',
    value: '5000+'
  }];
  var _default = COMPANY_SIZE;
  _exports.default = _default;
});