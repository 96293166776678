define("riot/templates/welcome/about-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nu8je0hh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"about-you-page\"],[12],[10,\"div\"],[14,0,\"card\"],[12],[10,\"h2\"],[12],[2,\"About you\"],[13],[11,\"form\"],[4,[38,1],[[32,0],\"saveUser\"],[[\"on\"],[\"submit\"]]],[12],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your work email address\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,3],null,[[\"value\",\"name\",\"disabled\"],[[35,2,[\"email\"]],\"email\",true]]]],[13],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your full name\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,3],null,[[\"autofocus\",\"value\",\"name\",\"placeholder\"],[true,[35,2,[\"name\"]],\"name\",\"John Doe\"]]]],[13],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"p\"],[14,0,\"error\"],[12],[1,[34,0]],[13]],\"parameters\":[]}]]],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your position in the company\"],[13],[1,[30,[36,6],null,[[\"value\",\"options\"],[[35,2,[\"role\"]],[35,5]]]]],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your phone number\"],[10,\"small\"],[12],[2,\"optional\"],[13],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,3],null,[[\"value\",\"name\",\"placeholder\"],[[35,2,[\"phoneNumber\"]],\"phone\",\"+1 (123) 456-7890\"]]]],[13],[13],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,8],null,[[\"color\",\"label\",\"loading\",\"icon\",\"iconPosition\"],[\"purple\",\"Continue\",[35,7],\"arrow-narrow-right\",\"right\"]]]],[13],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"errorName\",\"action\",\"user\",\"input\",\"if\",\"roles\",\"select-input\",\"isLoading\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/welcome/about-you.hbs"
    }
  });

  _exports.default = _default;
});