define("riot/initializers/bugsnag", ["exports", "riot/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (_environment.default.environment !== 'development' && window.bugsnag) {
      window.bugsnagClient = bugsnag({
        apiKey: _environment.default.bugsnag.key,
        releaseStage: _environment.default.environment
      });

      Ember.onerror = function (error) {
        window.bugsnagClient.notify(error);
      };
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});