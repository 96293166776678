define("riot/templates/groups/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S6xeyL7q",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],null,[[\"close\"],[[30,[36,0],[[32,0],\"close\"],null]]],[[\"default\"],[{\"statements\":[[11,\"form\"],[4,[38,0],[[32,0],\"new\"],[[\"on\"],[\"submit\"]]],[12],[10,\"section\"],[12],[10,\"h3\"],[12],[2,\"Create a new group\"],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Name\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,2],null,[[\"autofocus\",\"value\",\"name\",\"placeholder\"],[true,[35,1],\"name\",\"Example: sales\"]]]],[13],[13],[10,\"label\"],[14,\"for\",\"\"],[12],[10,\"span\"],[12],[2,\"Employees\"],[13],[1,[30,[36,4],null,[[\"resource\",\"value\"],[\"employee\",[35,3]]]]],[13],[13],[10,\"section\"],[14,0,\"actions\"],[12],[1,[30,[36,8],null,[[\"color\",\"label\",\"loading\",\"disabled\"],[\"purple\",\"Create\",[35,7],[30,[36,6],[[35,5]],null]]]]],[13],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"name\",\"input\",\"employeesArray\",\"array-input\",\"valid\",\"not\",\"isLoading\",\"smart-button\",\"slide-over\"]}",
    "meta": {
      "moduleName": "riot/templates/groups/new.hbs"
    }
  });

  _exports.default = _default;
});