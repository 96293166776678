define("riot/utils/industries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INDUSTRIES = [{
    key: 'banking',
    value: '🏦 Banking'
  }, {
    key: 'business-professional-services',
    value: '💼 Business & Professional services'
  }, {
    key: 'construction-engineering',
    value: '🏗 Construction & Engineering'
  }, {
    key: 'consumer-goods',
    value: '🛒 Consumer Goods'
  }, {
    key: 'creative-agency',
    value: '🎨 Creative Agency'
  }, {
    key: 'education',
    value: '📚 Education'
  }, {
    key: 'energy-utilities',
    value: '⚡️ Energy & Utilities'
  }, {
    key: 'entertainment-media',
    value: '📺 Entertainment & Media'
  }, {
    key: 'financial-services',
    value: '💵 Financial Services'
  }, {
    key: 'governement',
    value: '🏛 Governement'
  }, {
    key: 'healthcare-pharmaceutical',
    value: '🏨 Healthcare & Pharmaceutical'
  }, {
    key: 'hospitality',
    value: '🙌🏻 Hospitality'
  }, {
    key: 'insurance',
    value: '🗂 Insurance'
  }, {
    key: 'legal',
    value: '⚖️ Legal'
  }, {
    key: 'manufacturing',
    value: '🏭 Manufacturing'
  }, {
    key: 'non-profit',
    value: '🎗 Non Profit'
  }, {
    key: 'retail-wholesale',
    value: '🛍 Retail & Wholesale'
  }, {
    key: 'real-estate',
    value: '🏡 Real Estate'
  }, {
    key: 'technology',
    value: '💻 Technology'
  }, {
    key: 'telecommunications',
    value: '📡 Telecommunications'
  }, {
    key: 'transportation',
    value: '🚌 Transportation'
  }, {
    key: 'other',
    value: '🤔 Other'
  }];
  var _default = INDUSTRIES;
  _exports.default = _default;
});