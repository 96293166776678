define("riot/routes/settings", ["exports", "emberfire/mixins/realtime-route"], function (_exports, _realtimeRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_realtimeRoute.default, {
    currentUser: Ember.inject.service(),
    model: function model() {
      return this.store.findRecord('user', this.currentUser.user.uid);
    }
  });

  _exports.default = _default;
});