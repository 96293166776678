define("riot/templates/components/smart-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nchEcUer",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,2],null,[[\"icon\"],[[35,1]]]]]],\"parameters\":[]}]]],[1,[30,[36,10],null,[[\"value\",\"name\",\"placeholder\",\"autofocus\",\"autocomplete\",\"disabled\"],[[35,9],[35,8],[35,7],[35,6],[35,5],[35,4]]]]],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"append\"],[12],[1,[34,0]],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"append\",\"icon\",\"hero-icon\",\"if\",\"disabled\",\"autocomplete\",\"autofocus\",\"placeholder\",\"name\",\"localValue\",\"input\"]}",
    "meta": {
      "moduleName": "riot/templates/components/smart-input.hbs"
    }
  });

  _exports.default = _default;
});