define("riot/routes/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return this.store.findRecord('user', this.currentUser.user.uid);
    }
  });

  _exports.default = _default;
});