define("riot/components/google-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['action-panel', 'with-ol', 'google-action-panel'],
    tagName: 'main',
    currentUser: Ember.inject.service(),
    googleAdminLink: Ember.computed('currentUser.company.{domain,provider}', function () {
      return "https://admin.google.com/".concat(this.get('currentUser.company.domain'), "/AdminHome?hl=en&pli=1&fral=1#ServiceSettings/service=email&subtab=filters");
    }),
    actions: {
      tryOut: function tryOut() {
        this.tryOut();
      }
    }
  });

  _exports.default = _default;
});