define("riot/controllers/campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      link: function link(campaign) {
        if (campaign.get('isDraft')) {
          this.transitionToRoute('campaign.edit', campaign);
        } else {
          this.transitionToRoute('campaign.index', campaign);
        }
      }
    }
  });

  _exports.default = _default;
});