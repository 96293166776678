define("riot/templates/phishing/1password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rqd+MaDe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing onepassword\"],[12],[10,\"div\"],[14,0,\"locked-app-icon\"],[12],[10,\"div\"],[14,0,\"icon\"],[12],[10,\"img\"],[14,\"src\",\"/images/1password/gradient.svg\"],[14,0,\"gradient\"],[12],[13],[10,\"img\"],[14,\"src\",\"/images/1password/logo.svg\"],[14,0,\"logo\"],[12],[13],[13],[13],[10,\"section\"],[12],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"h1\"],[12],[2,\"Sign in to your 1Password account\"],[13],[10,\"p\"],[14,0,\"input-label\"],[12],[2,\"Email\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"disabled\"],[[35,1,[\"email\"]],true]]]],[13],[10,\"p\"],[14,0,\"input-label\"],[12],[2,\"Secret Key\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"autofocus\"],[[35,4],true]]]],[13],[10,\"p\"],[14,0,\"input-label\"],[12],[2,\"Master Password\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"type\"],[[35,1,[\"password\"]],\"password\"]]]],[13],[6,[37,5],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,6],null,[[\"label\"],[\"This is a public or shared computer\"]]]],[1,[30,[36,7],null,[[\"label\",\"loading\"],[\"Sign In\",[35,1,[\"isLoading\"]]]]]],[13],[13],[10,\"footer\"],[12],[2,\"© 2020 1Password. All rights reserved. Suite 303, 49 Spadina Ave, Toronto, Ontario, M5V 2J1, Canada.\"],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"input\",\"secret_key\",\"if\",\"checkbox-input\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/1password.hbs"
    }
  });

  _exports.default = _default;
});