define("riot/components/campaign-recipients-setting", ["exports", "ember-inflector", "riot/helpers/pluralize"], function (_exports, _emberInflector, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['campaign-recipients-setting'],
    currentUser: Ember.inject.service(),
    init: function init() {
      var value = this.value;

      this._super.apply(this, arguments);

      this.set('selection', {
        group: [],
        employee: []
      });

      if (!Ember.isEmpty(value) && Object.keys(value).length) {
        var type = Object.keys(value).get('firstObject');
        var singularType = type === 'company' ? 'company' : type.slice(0, type.length - 1);
        this.set('type', singularType);
        this.set("selection.".concat(singularType), value[type]);
        this.set('isExpanded', false);
      }
    },
    types: [{
      key: 'company',
      name: 'Send to all active employees'
    }, {
      key: 'group',
      // This needs to be a `resource`
      name: 'Send to specific groups'
    }, {
      key: 'employee',
      name: 'Send to specific employees'
    }],
    isDirty: Ember.computed('recipients', 'value', function () {
      return !Ember.isEqual(this.recipients, this.value);
    }),
    isValid: Ember.computed.bool('recipients'),
    details: Ember.computed('currentUser.company.statistics.current.employees.total', 'isExpanded', 'value.company', 'value.employees.length', 'value.groups.length', function () {
      var details = {
        title: 'Choose your audience',
        description: 'Specify which employees should receive the attacks'
      };

      if (!this.isExpanded) {
        if (this.get('value.company')) {
          details.title = "All employees (".concat(this.get('currentUser.company.statistics.current.employees.total'), ")");
          details.description = 'All your employees are part of this campaign';
        }

        if (this.get('value.employees')) {
          details.title = "".concat(this.get('value.employees.length'), " selected ").concat((0, _pluralize.pluralizer)([this.get('value.employees.length'), 'employee']));
          details.description = 'A selection of employees are part of this campaign';
        }

        if (this.get('value.groups')) {
          details.title = "All employees in ".concat(this.get('value.groups.length'), " selected ").concat((0, _pluralize.pluralizer)([this.get('value.groups.length'), 'group']));
          details.description = "All employees in the selected groups are part of this campaign";
        }
      }

      return details;
    }),
    recipients: Ember.computed('currentUser.company.id', 'selection.{employee,group}.length', 'type', function () {
      var type = this.type;
      var recipients = {};

      if (type === 'company') {
        recipients.company = this.get('currentUser.company.id');
      } else if (this.get("selection.".concat(type, ".length"))) {
        recipients[(0, _emberInflector.pluralize)(type)] = this.get("selection.".concat(type));
      }

      return Object.keys(recipients).length === 0 ? false : recipients;
    }),
    actions: {
      save: function save() {
        if (this.isDirty) {
          this.set('value', this.recipients);

          if (this.save) {
            this.save('recipients');
          }
        }
      }
    }
  });

  _exports.default = _default;
});