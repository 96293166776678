define("riot/templates/components/smart-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Fat8pA+",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[11,\"ul\"],[24,0,\"item\"],[4,[38,0],[[32,0],\"itemClicked\",[32,1]],[[\"on\"],[\"click\"]]],[12],[18,2,[[32,1]]],[13]],\"parameters\":[1]}]]],[6,[37,3],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"ul\"],[14,0,\"skeleton\"],[12],[10,\"li\"],[14,0,\"main\"],[12],[13],[10,\"li\"],[12],[13],[13],[10,\"ul\"],[14,0,\"skeleton\"],[12],[10,\"li\"],[14,0,\"main\"],[12],[13],[10,\"li\"],[12],[13],[13],[10,\"ul\"],[14,0,\"skeleton\"],[12],[10,\"li\"],[14,0,\"main\"],[12],[13],[10,\"li\"],[12],[13],[13]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"load-more\"],[12],[11,\"a\"],[4,[38,0],[[32,0],\"loadMore\"],[[\"on\"],[\"click\"]]],[12],[1,[30,[36,1],null,[[\"icon\"],[\"cheveron-down\"]]]],[2,\"load more\"],[13],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"hero-icon\",\"hasMore\",\"if\",\"filteredItems\",\"-track-array\",\"each\",\"isLoading\"]}",
    "meta": {
      "moduleName": "riot/templates/components/smart-table.hbs"
    }
  });

  _exports.default = _default;
});