define("riot/controllers/attack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['campaignId'],
    attack: Ember.computed.alias('model'),
    campaign: Ember.computed('campaignId', 'store', function () {
      if (this.campaignId) {
        return this.store.findRecord('campaign', this.campaignId);
      }
    }),
    title: Ember.computed('attack.{name,templateName}', function () {
      if (this.get('attack.name')) {
        return this.get('attack.name');
      } else {
        return "".concat(this.get('attack.templateName'), " attack");
      }
    }),
    breadcrumb: Ember.computed('attack.employee.{id,name}', 'campaign.{id,_name}', function () {
      var breadcrumb = [{
        label: 'Employees',
        link: 'employees'
      }, {
        label: this.get('attack.employee.name'),
        link: 'employee',
        resource: this.get('attack.employee.id')
      }];

      if (this.campaign) {
        return [{
          label: 'Campaigns',
          link: 'campaigns'
        }, {
          label: this.get('campaign._name'),
          link: 'campaign',
          resource: this.get('campaign.id')
        }, {
          label: 'Attacks',
          link: 'campaign',
          resource: this.get('campaign.id')
        }];
      }

      return breadcrumb;
    })
  });

  _exports.default = _default;
});