define("riot/templates/phishing/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r9gy6NYW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing default\"],[12],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[6,[37,3],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your email address\"],[13],[1,[30,[36,4],null,[[\"value\",\"disabled\"],[[35,1,[\"email\"]],true]]]],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your password\"],[13],[1,[30,[36,4],null,[[\"value\",\"type\",\"autofocus\",\"disabled\",\"submit\"],[[35,1,[\"password\"]],\"password\",true,[35,1,[\"hacked\"]],[30,[36,2],[[32,0],\"login\"],[[\"target\"],[[35,1]]]]]]]],[13],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"color\",\"icon\",\"label\",\"loading\"],[\"blue\",\"angle-right\",\"Connect\",[35,1,[\"isLoading\"]]]]]],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"if\",\"input\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/default.hbs"
    }
  });

  _exports.default = _default;
});