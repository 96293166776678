define("riot/initializers/computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Ember.computed.includes = function (enumerable, value) {
      return Ember.computed.apply(null, [enumerable + '.[]', {
        get: function get() {
          var arr = this.get(enumerable);
          return Ember.isArray(arr) && arr.includes(value);
        },
        set: function set(key, val) {
          var arr = this.get(enumerable);

          if (Ember.isArray(arr)) {
            if (val === true && !arr.includes(value)) {
              arr.addObject(value);
            }

            if (val === false) {
              arr.removeObject(value);
            }
          }

          return val;
        }
      }]);
    };
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});