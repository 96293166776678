define("riot/routes/group", ["exports", "emberfire/mixins/realtime-route"], function (_exports, _realtimeRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_realtimeRoute.default, {
    model: function model(params) {
      return this.store.findRecord('group', params.id);
    }
  });

  _exports.default = _default;
});