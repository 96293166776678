define("riot/templates/phishing/dropbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KBRx/eW4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing dropbox\"],[12],[10,\"header\"],[12],[10,\"img\"],[14,\"src\",\"/images/dropbox/box.svg\"],[14,\"width\",\"46px\"],[12],[13],[10,\"img\"],[14,\"src\",\"/images/dropbox/logo.svg\"],[14,\"width\",\"120px\"],[12],[13],[13],[10,\"section\"],[12],[10,\"div\"],[14,0,\"illustration\"],[12],[10,\"img\"],[14,\"src\",\"/images/dropbox/illustration.png\"],[14,\"width\",\"350px\"],[12],[13],[13],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"h3\"],[12],[2,\"Sign in\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"disabled\",\"placeholder\"],[[35,1,[\"email\"]],true,\"Your email address\"]]]],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"type\",\"autofocus\",\"placeholder\"],[[35,1,[\"password\"]],\"password\",true,\"Password\"]]]],[13],[6,[37,4],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"label\"],[\"Remember me\"]]]],[1,[30,[36,6],null,[[\"label\",\"loading\"],[\"Connect\",[35,1,[\"isLoading\"]]]]]],[13],[10,\"p\"],[14,0,\"captcha\"],[12],[2,\"This page is protected by reCAPTCHA, and subject to the Google Privacy Policy and Terms of service.\"],[13],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"input\",\"if\",\"checkbox-input\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/dropbox.hbs"
    }
  });

  _exports.default = _default;
});