define("riot/templates/components/campaign-recipients-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4hGZ0tQM",
    "block": "{\"symbols\":[\"recipientType\"],\"statements\":[[6,[37,15],null,[[\"icon\",\"title\",\"description\",\"action\",\"isExpanded\",\"isValid\"],[\"outline:user-group\",[35,14,[\"title\"]],[35,14,[\"description\"]],[30,[36,13],[[32,0],\"save\"],null],[35,12],[35,11]]],[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"value\",\"options\"],[[35,0],[35,9]]],[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,7],[[30,[36,4],[[32,1,[\"key\"]],\"company\"],null],[30,[36,4],[[35,0],\"company\"],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"badge blue\"],[12],[1,[35,6,[\"company\",\"statistics\",\"current\",\"employees\",\"active\"]]],[13]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,7],[[30,[36,4],[[32,1,[\"key\"]],\"group\"],null],[30,[36,4],[[35,0],\"group\"],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"badge blue\"],[12],[1,[35,1,[\"group\",\"length\"]]],[13]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,7],[[30,[36,4],[[32,1,[\"key\"]],\"employee\"],null],[30,[36,4],[[35,0],\"employee\"],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"badge blue\"],[12],[1,[35,1,[\"employee\",\"length\"]]],[13]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,8],[[30,[36,4],[[35,0],\"company\"],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[35,0],[32,1,[\"key\"]]],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,3],null,[[\"resource\",\"value\"],[[35,0],[30,[36,2],[[35,1],[35,0]],null]]]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"type\",\"selection\",\"get\",\"array-input\",\"is-eq\",\"if\",\"currentUser\",\"and\",\"not\",\"types\",\"radio-group\",\"isValid\",\"isExpanded\",\"action\",\"details\",\"campaign-setting\"]}",
    "meta": {
      "moduleName": "riot/templates/components/campaign-recipients-setting.hbs"
    }
  });

  _exports.default = _default;
});