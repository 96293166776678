define("riot/models/campaign", ["exports", "@ember-data/model", "ember-data", "riot/helpers/pluralize"], function (_exports, _model, _emberData, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // read only
    createdAt: (0, _model.attr)('timestamp'),
    updatedAt: (0, _model.attr)('timestamp'),
    nextAttack: (0, _model.attr)('timestamp'),
    creator: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    statistics: (0, _model.attr)(),
    company: (0, _model.belongsTo)('company'),
    type: (0, _model.attr)('string'),
    frequency: (0, _model.attr)('string'),
    autoSendTraining: (0, _model.attr)(),
    // can be null, false and true
    status: (0, _model.attr)('string', {
      defaultValue: 'draft'
    }),
    recipients: (0, _model.attr)(),
    templates: (0, _model.attr)(),
    isOneOff: Ember.computed.equal('type', 'one-off'),
    isWeekly: Ember.computed.equal('frequency', 'weekly'),
    // Status
    isDraft: Ember.computed.equal('status', 'draft'),
    isRunning: Ember.computed.equal('status', 'running'),
    isPaused: Ember.computed.equal('status', 'paused'),
    isCompleted: Ember.computed.equal('status', 'completed'),
    totalAttacks: Ember.computed('statistics', function () {
      var _this = this;

      var total = 0;

      if (this.statistics) {
        Object.keys(this.statistics).forEach(function (period) {
          return total += _this.statistics[period].attacks.sent;
        });
      }

      return total;
    }),
    canBeDeleted: Ember.computed.equal('totalAttacks', 0),
    statusColor: Ember.computed('isCompleted', 'isPaused', 'isRunning', 'status', function () {
      if (this.isRunning) {
        return 'green';
      } else if (this.isPaused) {
        return 'yellow';
      } else if (this.isCompleted) {
        return 'blue';
      }

      return 'gray';
    }),
    isValid: Ember.computed('autoSendTraining', 'frequency', 'recipients', 'templateNames.length', 'templates', 'type', function () {
      if (!(this.recipients instanceof Object) || Object.keys(this.recipients).length < 1) {
        return false;
      }

      if (this.templateNames.length < 1) {
        return false;
      }

      if (!['once', 'weekly', 'monthly', 'quarterly', 'yearly'].includes(this.frequency)) {
        return false;
      }

      if (!['one-off', 'recurring'].includes(this.type)) {
        return false;
      }

      if (typeof this.autoSendTraining !== 'boolean') {
        return false;
      }

      return true;
    }),
    recipientsGroups: Ember.computed('recipients.groups', 'store', function () {
      var _this2 = this;

      var groupIds = this.get('recipients.groups') || [];
      var groups = groupIds.map(function (groupId) {
        return _this2.store.findRecord('group', groupId);
      });
      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(groups)
      });
    }),
    attacks: Ember.computed('company.id', 'id', 'store', function () {
      var _this3 = this;

      return this.store.query('attack', {
        query: function query(ref) {
          return ref.where('campaign', '==', _this3.id).where('company', '==', _this3.get('company.id'));
        }
      });
    }),
    audience: Ember.computed('attacks.length', 'company.statistics.current.employees.total', 'isCompleted', 'recipients.employees.length', 'recipients.{company,groups}', 'recipientsGroups.length', function () {
      if (this.isCompleted) {
        return this.attacks.mapBy('employee').uniq().length;
      } else if (this.get('recipients.company')) {
        return this.get('company.statistics.current.employees.total');
      } else if (this.get('recipients.employees')) {
        return this.get('recipients.employees.length');
      } else if (this.get('recipients.groups')) {
        return this.recipientsGroups.reduce(function (acc, group) {
          return acc.addObjects(group.get('employees'));
        }, []).length;
      }

      return 0;
    }),
    templateNames: Ember.computed('templates', function () {
      var _this4 = this;

      var templateNames = [];
      Object.keys(this.templates || {}).forEach(function (template) {
        Object.keys(_this4.templates[template] || {}).forEach(function (service) {
          templateNames.addObject("".concat(template, "/").concat(service));
        });
      });
      return templateNames;
    }),
    _frequency: Ember.computed('frequency', 'type', function () {
      if (!this.frequency) {
        return 'Unknown';
      }

      if (this.frequency !== 'once') {
        return "".concat(this.frequency.capitalize(), " ").concat(this.type);
      } else {
        return 'One-off';
      }
    }),
    _name: Ember.computed('_frequency', 'frequency', 'name', 'recipients.employees.length', 'recipients.groups.length', {
      get: function get() {
        var name = this.name;

        if (Ember.isEmpty(name)) {
          name = 'Untitled campaign';

          if (!Ember.isEmpty(this.frequency)) {
            name = "".concat(this._frequency, " campaign");
          }

          if (!Ember.isEmpty(this.recipients)) {
            var key = Object.keys(this.recipients).pop();

            if (key === 'company') {
              name += ' to all employees';
            } else if (key === 'employees') {
              name += " to ".concat(this.get('recipients.employees.length'), " ").concat((0, _pluralize.pluralizer)([this.get('recipients.employees.length'), 'employee']));
            } else if (key === 'groups') {
              name += " to ".concat(this.get('recipients.groups.length'), " ").concat((0, _pluralize.pluralizer)([this.get('recipients.groups.length'), 'group']));
            }
          }
        }

        return name;
      },
      set: function set(key, value) {
        this.set('name', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});