define("riot/templates/phishing/quickbooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4fj93UW7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing quickbooks\"],[12],[10,\"header\"],[12],[10,\"img\"],[14,\"src\",\"/images/quickbooks/logo.png\"],[14,\"width\",\"190px\"],[12],[13],[13],[10,\"section\"],[12],[10,\"div\"],[14,\"src\",\"/images/quickbooks/logo.png\"],[14,0,\"intuit\"],[12],[13],[10,\"div\"],[14,\"src\",\"/images/quickbooks/logo.png\"],[14,0,\"logo-quickbooks\"],[12],[13],[10,\"hr\"],[12],[13],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"h1\"],[12],[2,\"Sign in\"],[13],[10,\"p\"],[14,0,\"subtitle\"],[12],[2,\"One account for everything Intuit, including QuickBooks.\"],[13],[10,\"p\"],[14,0,\"input-label\"],[12],[2,\"Email or user ID\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"disabled\"],[[35,1,[\"email\"]],true]]]],[13],[10,\"p\"],[14,0,\"input-label\"],[12],[2,\"Password\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"type\",\"autofocus\"],[[35,1,[\"password\"]],\"password\",true]]]],[13],[6,[37,4],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"label\"],[\"Remember me\"]]]],[1,[30,[36,6],null,[[\"label\",\"loading\"],[\"Sign In\",[35,1,[\"isLoading\"]]]]]],[13],[10,\"p\"],[14,0,\"captcha\"],[12],[2,\"This page is protected by Google reCAPTCHA to ensure you're not a bot.\"],[13],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"input\",\"if\",\"checkbox-input\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/quickbooks.hbs"
    }
  });

  _exports.default = _default;
});