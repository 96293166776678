define("riot/helpers/timestamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timestamp = timestamp;
  _exports.default = void 0;

  function timestamp(params) {
    if (!params[0] || !params[0].seconds) {
      return 'now';
    } else {
      var date = new Date(params[0].seconds * 1000);
      return "".concat(date.toLocaleDateString(), " ").concat(date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      }));
    }
  }

  var _default = Ember.Helper.helper(timestamp);

  _exports.default = _default;
});