define("riot/helpers/countdown", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref, params) {
      var _ref2 = (0, _toArray2.default)(_ref),
          date = _ref2[0],
          args = _ref2.slice(1);

      var countdown = {};

      if (params.date) {
        date = params.date;
      }

      if (date === undefined) {
        return 'unknown';
      }

      if (typeof date === 'string') {
        return date;
      }

      if (date.seconds) {
        date = new Date(date.seconds * 1000);
      }

      if (params.upcomingOnly && date < new Date()) {
        return 'soon';
      }

      var diff = Math.round(Math.abs(Date.now() - date) / 1000);

      if (diff < 60) {
        countdown = {
          type: 'second',
          value: diff
        };
      } else if (diff < 3600) {
        countdown = {
          type: 'minute',
          value: diff / 60
        };
      } else if (diff < 3600 * 24) {
        countdown = {
          type: 'hour',
          value: diff / 3600
        };
      } else {
        countdown = {
          type: 'day',
          value: diff / (3600 * 24)
        };
      }

      countdown.value = Math.round(countdown.value);

      if (countdown.type === 'second') {
        return 'now';
      }

      if (date > new Date()) {
        return "in ".concat(countdown.value, " ").concat(countdown.type).concat(countdown.value > 1 ? 's' : '');
      } else if (params.short) {
        return "".concat(countdown.value, " ").concat(countdown.type).concat(countdown.value > 1 ? 's' : '');
      } else {
        return "".concat(countdown.value, " ").concat(countdown.type).concat(countdown.value > 1 ? 's' : '', " ago");
      }
    }
  });

  _exports.default = _default;
});