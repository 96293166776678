define("riot/templates/train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EU+7rVYH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"training\"],[12],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"login-page\"],[12],[10,\"div\"],[14,0,\"loader\"],[12],[2,\"Please wait…\"],[13],[13]],\"parameters\":[]},{\"statements\":[[10,\"img\"],[14,\"src\",\"/images/logo-dark.png\"],[14,0,\"logo\"],[12],[13],[1,[30,[36,2],null,[[\"upcoming\",\"currentUser\"],[[35,1],[35,0]]]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"upcoming\",\"on-chat\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/train.hbs"
    }
  });

  _exports.default = _default;
});