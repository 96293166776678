define("riot/templates/components/checkbox-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fGjKX1JO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,1],null,[[\"type\",\"checked\"],[\"checkbox\",[35,0]]]]],[10,\"strong\"],[12],[1,[34,2]],[13],[18,1,null]],\"hasEval\":false,\"upvars\":[\"value\",\"input\",\"label\"]}",
    "meta": {
      "moduleName": "riot/templates/components/checkbox-input.hbs"
    }
  });

  _exports.default = _default;
});