define("riot/templates/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MqTcsZJb",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"title\"],[\"Set up your account\"]]]],[6,[37,3],[[35,2,[\"company\",\"isGoogle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,4],null,[[\"tryOut\"],[[30,[36,0],[[32,0],\"tryOut\"],null]]]]]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2,[\"company\",\"isMicrosoft\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,1],null,[[\"tryOut\"],[[30,[36,0],[[32,0],\"tryOut\"],null]]]]]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[12],[2,\"Coming soon\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"outlook-setup\",\"currentUser\",\"if\",\"google-setup\",\"header-bar\"]}",
    "meta": {
      "moduleName": "riot/templates/setup.hbs"
    }
  });

  _exports.default = _default;
});