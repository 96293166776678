define("riot/templates/components/employee-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xvHAUZPw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"metrics\"],[12],[10,\"li\"],[14,0,\"badge-container\"],[12],[1,[30,[36,2],null,[[\"class\",\"employee\"],[\"metric\",[35,0]]]]],[13],[10,\"li\"],[14,0,\"main-metric\"],[12],[10,\"div\"],[14,0,\"metric\"],[12],[10,\"p\"],[12],[2,\"Last attack\"],[13],[10,\"strong\"],[14,0,\"large primary\"],[12],[6,[37,3],[[35,0,[\"lastAttack\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,1],null,[[\"date\"],[[35,0,[\"lastAttack\",\"overall\"]]]]]]],\"parameters\":[]},{\"statements\":[[2,\"never\"]],\"parameters\":[]}]]],[13],[13],[13],[10,\"li\"],[14,0,\"aside\"],[12],[10,\"div\"],[14,0,\"metric\"],[12],[10,\"p\"],[12],[1,[30,[36,4],[[35,0,[\"campaigns\",\"length\"]],\"Campaign\"],null]],[13],[10,\"strong\"],[14,0,\"large\"],[12],[1,[35,0,[\"campaigns\",\"length\"]]],[13],[13],[13],[13],[1,[30,[36,5],null,[[\"value\"],[[35,0,[\"statistics\"]]]]]]],\"hasEval\":false,\"upvars\":[\"employee\",\"countdown\",\"health-badge\",\"if\",\"pluralize\",\"statistics-waterfall\"]}",
    "meta": {
      "moduleName": "riot/templates/components/employee-statistics.hbs"
    }
  });

  _exports.default = _default;
});