define("riot/routes/phishing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, params) {
      document.title = 'Sign up to your account';

      if (params.key) {
        var attack = this.store.createRecord('attack', {
          id: params.attack
        });
        controller.set('attack', attack);
      }
    }
  });

  _exports.default = _default;
});