define("riot/templates/components/activity-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rNKbEG2h",
    "block": "{\"symbols\":[\"activity\"],\"statements\":[[10,\"ul\"],[14,0,\"feed\"],[12],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,1],null,[[\"activity\"],[[32,1]]]]]],\"parameters\":[1]}]]],[13],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[11,\"a\"],[4,[38,0],[[32,0],\"loadMore\"],[[\"on\"],[\"click\"]]],[12],[2,\"load more\"],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"activity-item\",\"activities\",\"-track-array\",\"each\",\"hasMore\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/components/activity-feed.hbs"
    }
  });

  _exports.default = _default;
});