define("riot/services/intercom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    show: function show() {
      window.Intercom('show');
    },
    off: function off() {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    },
    newMessage: function newMessage() {
      window.Intercom('showNewMessage');
    }
  });

  _exports.default = _default;
});