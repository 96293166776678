define("riot/templates/welcome/domain-used", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zIf0NbD5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"domain-used\"],[12],[10,\"div\"],[14,0,\"card\"],[12],[10,\"h2\"],[12],[2,\"Riot at \"],[1,[35,0,[\"company\",\"name\"]]],[13],[10,\"p\"],[12],[2,\"There's already an account for \"],[1,[35,0,[\"company\",\"name\"]]],[2,\" on Riot. You will have to be manually approved to join. We notified the main account.\"],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"currentUser\"]}",
    "meta": {
      "moduleName": "riot/templates/welcome/domain-used.hbs"
    }
  });

  _exports.default = _default;
});