define("riot/templates/components/campaign-training-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u9/8BhFl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],null,[[\"icon\",\"title\",\"description\",\"action\",\"isExpanded\",\"isValid\"],[\"outline:chat-alt-2\",[35,8,[\"title\"]],[35,8,[\"description\"]],[30,[36,1],[[32,0],\"save\"],null],[35,7],[35,6]]],[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"value\",\"label\"],[[35,3],\"Send a training\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"Send a training when an employee is found to be vulnerable.\"],[13]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"color\",\"label\",\"icon\",\"iconPosition\",\"click\"],[\"white\",\"Preview the training\",\"arrow-narrow-right\",\"right\",[30,[36,1],[[32,0],\"toggleTraining\"],null]]]]],[13]],\"parameters\":[]}]]],[6,[37,11],[[35,10]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"close\"],[[30,[36,1],[[32,0],\"toggleTraining\"],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],null,[[\"training\"],[\"phishing\"]]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"training-preview\",\"action\",\"slide-over\",\"training\",\"checkbox-input\",\"smart-button\",\"isValid\",\"isExpanded\",\"details\",\"campaign-setting\",\"showTraining\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/components/campaign-training-setting.hbs"
    }
  });

  _exports.default = _default;
});