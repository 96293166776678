define("riot/templates/welcome/about-your-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GDXVVwnp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"about-your-company-page\"],[12],[10,\"div\"],[14,0,\"card\"],[12],[10,\"h2\"],[12],[2,\"About your company\"],[13],[11,\"form\"],[4,[38,1],[[32,0],\"saveCompany\"],[[\"on\"],[\"submit\"]]],[12],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your company name\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,3],null,[[\"value\",\"name\"],[[35,2,[\"company\",\"name\"]],\"company-name\"]]]],[13],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"p\"],[14,0,\"error\"],[12],[1,[34,0]],[13]],\"parameters\":[]}]]],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your company size\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,6],null,[[\"value\",\"options\"],[[35,2,[\"company\",\"size\"]],[35,5]]]]],[13],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Industry\"],[13],[1,[30,[36,6],null,[[\"value\",\"options\"],[[35,2,[\"company\",\"industry\"]],[35,7]]]]],[13],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,8],null,[[\"color\",\"label\",\"icon\",\"link\"],[\"white\",\"Back\",\"arrow-narrow-left\",\"welcome.about-you\"]]]],[1,[30,[36,8],null,[[\"color\",\"label\",\"loading\"],[\"purple\",\"Done\",[35,9]]]]],[13],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"errorName\",\"action\",\"currentUser\",\"input\",\"if\",\"companySizes\",\"select-input\",\"industries\",\"smart-button\",\"isLoading\"]}",
    "meta": {
      "moduleName": "riot/templates/welcome/about-your-company.hbs"
    }
  });

  _exports.default = _default;
});