define("riot/templates/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2t4xO8Vy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"notification\"],[12],[10,\"div\"],[14,0,\"message\"],[12],[1,[30,[36,1],[[35,0,[\"message\"]]],null]],[13],[11,\"a\"],[4,[38,2],[[32,0],\"close\"],[[\"on\"],[\"click\"]]],[12],[10,\"i\"],[14,0,\"fa fa-close\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[10,\"div\"],[15,5,[34,3]],[14,0,\"progress-bar\"],[12],[13],[13]],\"hasEval\":false,\"upvars\":[\"notification\",\"markdown-to-html\",\"action\",\"style\"]}",
    "meta": {
      "moduleName": "riot/templates/components/notification-message.hbs"
    }
  });

  _exports.default = _default;
});