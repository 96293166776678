define("riot/components/checkbox-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['checkbox-input'],
    classNameBindings: ['disabled'],
    attributeBindings: ['for'],
    valueChanged: Ember.observer('value', function () {
      if (this.action) {
        this.action(this.value);
      }
    })
  });

  _exports.default = _default;
});