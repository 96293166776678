define("riot/components/smart-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input'],
    classNameBindings: ['input', 'icon:has-icon'],
    append: null,
    setOutsideValue: Ember.observer('localValue', function () {
      var value = this.localValue || '';

      if (this.append && value.substr(this.append.length * -1) !== this.append) {
        value = "".concat(value).concat(this.append);
      }

      this.set('value', value);
    })
  });

  _exports.default = _default;
});