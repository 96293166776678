define("riot/templates/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/APdqEG",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[10,\"header\"],[12],[10,\"div\"],[14,0,\"header-bar\"],[12],[13],[13],[10,\"div\"],[14,0,\"welcome\"],[12],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[13]],\"hasEval\":false,\"upvars\":[\"nav-bar\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "riot/templates/welcome.hbs"
    }
  });

  _exports.default = _default;
});