define("riot/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e68Kfvv1",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"notification-center\"],[12],[10,\"ul\"],[14,0,\"notifications\"],[12],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,5],null,[[\"notification\"],[[32,1]]]]]],\"parameters\":[1]}]]],[13],[13]],\"parameters\":[]}]]],[6,[37,4],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"loading-page\"],[12],[10,\"div\"],[14,0,\"loader\"],[12],[2,\"Please wait…\"],[13],[13]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[34,2]],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"parameters\":[]},{\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"nav-bar\",\"showPlatform\",\"if\",\"notification-message\",\"notifications\",\"-track-array\",\"each\",\"showAppLoading\"]}",
    "meta": {
      "moduleName": "riot/templates/application.hbs"
    }
  });

  _exports.default = _default;
});