define("riot/components/smart-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['smart-table'],
    attribute: 'name',
    // Filtering attribute
    filteredItems: Ember.computed.filter('items', ['filter'], function (item) {
      if (Ember.isEmpty(this.filter)) {
        return true;
      } else {
        return item.get('name').toLowerCase().includes(this.filter.toLowerCase());
      }
    }),
    actions: {
      itemClicked: function itemClicked(item) {
        if (this.itemClicked) {
          return this.itemClicked(item);
        }
      },
      loadMore: function loadMore() {
        if (this.loadMore) {
          this.loadMore();
        }
      }
    }
  });

  _exports.default = _default;
});