define("riot/components/email-preview", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['email-preview'],
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    api: Ember.inject.service(),
    format: function format(html) {
      var plain = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var types = {
        dynamic: /\[\[(.+?)\]\]/g,
        static: /{{(.+?)}}/g
      };
      var variables = this.mergedVariables;
      Object.keys(types).forEach(function (type) {
        var regex = types[type];
        html = html.replace(regex, function (str, name, id) {
          name = name.trim();
          var value = variables[name].value;

          if (plain) {
            return value;
          } else {
            if (name === 'raw') {
              return "<span class='raw-variable' contenteditable=true>".concat(value, "</span>");
            } else {
              return "<span id='variable-".concat(id, "' name='").concat(name, "' class='").concat(type, "-variable'>").concat(value, "</span>");
            }
          }
        });
      });
      return html;
    },
    email: Ember.computed('employee.id', 'preview.service', 'preview.template.id', function () {
      if (this.get('preview.template.id')) {
        return this.api.preview(this.get('preview.template.id'), this.get('preview.service'), this.get('employee.id'));
      }

      return null;
    }),
    html: Ember.computed('email.html', 'mergedVariables', function () {
      if (this.get('email.html')) {
        return this.format(this.get('email.html'));
      }
    }),
    subject: Ember.computed('email.subject', 'mergedVariables', function () {
      if (this.get('email.subject')) {
        return Ember.String.htmlSafe(this.format(this.get('email.subject'), true));
      }
    }),
    from: Ember.computed('email.from', 'mergedVariables', function () {
      if (this.get('email.from')) {
        return this.format(this.get('email.from'), true).replace(/<.+>/, '').trim();
      }
    }),
    fromName: Ember.computed('from', function () {
      if (this.from) {
        return Ember.String.htmlSafe(this.from);
      }
    }),
    fromAddress: Ember.computed('from', function () {
      if (this.from) {
        return "".concat(Ember.String.dasherize(this.from), "@noreply.link");
      }
    }),
    style: Ember.computed('size', function () {
      return "height:".concat(this.size, "px");
    }),
    resize: function resize() {
      var height = this.$('iframe').contents().find('.layout').outerHeight();
      height = Math.round(height * 0.8) + 50;
      this.set('size', height);
    },
    renderHTML: Ember.on('init', Ember.observer('html', function () {
      var _this = this;

      if (this.html) {
        this.set('selection', null);
        this.set('fromTop', 0);
        Ember.run.next(function () {
          _this.$('iframe').contents().find('html').html(_this.html);

          _this.$('iframe').contents().on('click', Ember.run.bind(_this, _this.emailClicked));

          _this.$('iframe').contents().on('focusout', Ember.run.bind(_this, _this.rawEdited));

          _this.$('iframe').contents().on('scroll', function () {
            _this.set('fromTop', _this.$('iframe').contents().scrollTop());
          });

          _this.$('iframe').contents().find('head').append((0, _jquery.default)('<link/>', {
            rel: 'stylesheet',
            href: '/assets/email-preview.css',
            type: 'text/css'
          }));

          Ember.run.next(function () {
            return _this.resize();
          });
        });
      }
    })),
    replaceVariables: function replaceVariables() {
      var _this2 = this;

      var emailVariables = Object.keys(this.get('email.variables')).reduce(function (acc, variable) {
        acc[variable] = _this2.get('email.variables')[variable].defaultValue;
        return acc;
      }, {});
      var variables = Object.keys(this.variables).reduce(function (acc, variable) {
        acc[variable] = _this2.variables[variable];
        acc[variable].options = acc[variable].options.map(function (option) {
          return option.replace(/\[\[(.+)\]\]/, function (i, v) {
            return emailVariables[v.trim()];
          });
        });
        acc[variable].value = acc[variable].value.replace(/\[\[(.+)\]\]/, function (i, v) {
          return emailVariables[v.trim()];
        });
        return acc;
      }, {});
      return variables;
    },
    mergedVariables: Ember.computed('variables', 'email.variables', function () {
      var _this3 = this;

      if (this.variables) {
        var variables = this.replaceVariables();
        Object.keys(this.get('email.variables')).forEach(function (variable) {
          if (variables[variable]) {
            variables[variable].options.addObject(_this3.get("email.variables")[variable].defaultValue);
          } else {
            variables[variable] = {
              description: _this3.get("email.variables")[variable].description,
              value: _this3.get("email.variables")[variable].defaultValue
            };
          }
        });
        return variables;
      }
    }),
    emailClicked: function emailClicked(e) {
      var _this4 = this;

      var target = (0, _jquery.default)(e.target);

      if (target.hasClass('static-variable') || target.hasClass('dynamic-variable')) {
        var variable = target.attr('name');
        this.set('selection', null);
        Ember.run.next(function () {
          var options = _this4.mergedVariables[variable].options || [];

          _this4.set('selection', {
            id: target.attr('id'),
            variable: variable,
            type: target.attr('class').split('-').shift(),
            description: _this4.mergedVariables[variable].description,
            options: options.uniq()
          });

          _this4.set('value', _this4.mergedVariables[variable].value);
        });
      } else {
        this.set('selection', null);
      }
    },
    // Editing the `raw` variable
    rawEdited: function rawEdited(e) {
      var target = (0, _jquery.default)(e.target);

      if (target.hasClass('raw-variable')) {
        var content = target.html();
        this.set('selection', {
          variable: 'raw'
        });
        this.send('setVariable', content);
      }
    },
    // Editing the subject in the `raw` template
    focusOut: function focusOut(e) {
      var target = (0, _jquery.default)(e.target);

      if (target.hasClass('raw-subject')) {
        var content = target.text();
        this.set('selection', {
          variable: 'subject'
        });
        this.send('setVariable', content);
        console.log(content);
      }
    },
    actions: {
      setVariable: function setVariable(value, br2nl) {
        this.setVariable(this.selection.variable, value);
        this.set('selection', null);
      }
    }
  });

  _exports.default = _default;
});