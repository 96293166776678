define("riot/models/enrollment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // read only
    createdAt: (0, _model.attr)('timestamp'),
    company: (0, _model.belongsTo)('company'),
    // part of the company employees
    employee: (0, _model.belongsTo)('employee'),
    attack: (0, _model.belongsTo)('attack'),
    // write only default values
    status: (0, _model.attr)('string', {
      defaultValue: 'not-started'
    }),
    key: (0, _model.attr)('string'),
    // related
    activities: (0, _model.hasMany)('activity')
  });

  _exports.default = _default;
});