define("riot/templates/components/health-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YEmgr2On",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"health\"]],\"safe\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"badge green\"],[12],[1,[30,[36,0],null,[[\"icon\"],[\"check\"]]]],[2,\"Safe\"],[13]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"health\"]],\"unsafe\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"badge red\"],[12],[1,[30,[36,0],null,[[\"icon\"],[\"x\"]]]],[2,\"Unsafe\"],[13]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"badge gray\"],[12],[1,[30,[36,0],null,[[\"icon\"],[\"dots-horizontal\"]]]],[2,\"Untested\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hero-icon\",\"employee\",\"is-eq\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/components/health-badge.hbs"
    }
  });

  _exports.default = _default;
});