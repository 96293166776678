define("riot/components/campaign-training-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['campaign-training-setting'],
    init: function init() {
      var value = this.value;

      this._super.apply(this, arguments);

      if (typeof value === 'boolean') {
        this.set('training', value);
        this.set('isExpanded', false);
      } else {
        this.set('training', false);
      }
    },
    details: Ember.computed('isExpanded', 'value', function () {
      var details = {
        title: 'Include a training',
        description: 'Specify if a training should be sent when an employee is found to be vulnerable'
      };
      var value = this.value;

      if (!this.isExpanded) {
        if (value === true) {
          details.title = 'With training';
          details.description = 'All vulnerable employees will receive a training afterward';
        } else if (value === false) {
          details.title = "Without training";
          details.description = "No training will be sent to vulnerable employees";
        }
      }

      return details;
    }),
    isDirty: Ember.computed('training', 'value', function () {
      return this.training !== this.value;
    }),
    isValid: Ember.computed('isExpanded', 'training', 'value', function () {
      var isNew = this.value === null && this.isExpanded === false;
      return !isNew && typeof this.training === 'boolean';
    }),
    training: false,
    showTraining: false,
    actions: {
      toggleTraining: function toggleTraining() {
        this.toggleProperty('showTraining');
      },
      save: function save() {
        if (this.isDirty) {
          this.set('value', this.training);

          if (this.save) {
            this.save('training');
          }
        }
      }
    }
  });

  _exports.default = _default;
});