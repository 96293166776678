define("riot/components/statistics-waterfall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['waterfall'],
    tagName: 'ul',
    percentage: true,
    statistics: Ember.computed('value', function () {
      var _this = this;

      var statistics = {
        attacks: 0,
        vulnerabilities: 0,
        trainings: 0,
        attacksVsVulnerabilities: 0,
        vulnerabilitiesVsTrainings: 0
      };

      if (this.value) {
        Object.keys(this.value).forEach(function (period) {
          statistics.attacks += _this.get("value.".concat(period, ".attacks.sent")) || 0;
          statistics.vulnerabilities += _this.get("value.".concat(period, ".attacks.success")) || 0;
          statistics.trainings += _this.get("value.".concat(period, ".enrollments.completed")) || 0;
        });
      }

      if (statistics.attacks) {
        statistics.attacksVsVulnerabilities = Math.round(statistics.vulnerabilities / statistics.attacks * 100);
      }

      if (statistics.vulnerabilities) {
        statistics.vulnerabilitiesVsTrainings = Math.round(statistics.trainings / statistics.vulnerabilities * 100);
      }

      return statistics;
    })
  });

  _exports.default = _default;
});