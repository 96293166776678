define("riot/templates/campaign/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vz9RH7hR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"title\",\"breadcrumb\",\"details\"],[[35,1,[\"_name\"]],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1,[\"isCompleted\"]]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],null,[[\"color\",\"icon\",\"label\",\"link\"],[\"gray\",\"pencil\",\"Edit the campaign\",\"campaign.edit\"]]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,[30,[36,6],null,[[\"campaign\"],[[35,1]]]]],[10,\"main\"],[12],[1,[30,[36,7],null,[[\"campaign\"],[[35,1]]]]],[13],[1,[30,[36,9],[[30,[36,8],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"smart-button\",\"campaign\",\"unless\",\"details\",\"breadcrumb\",\"header-bar\",\"campaign-statistics\",\"attacks-table\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "riot/templates/campaign/index.hbs"
    }
  });

  _exports.default = _default;
});