define("riot/helpers/money", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.money = money;
  _exports.default = void 0;

  function money(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        amount = _ref2[0],
        args = _ref2.slice(1);

    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (typeof params.amount !== 'undefined') {
      amount = params.amount;
    }

    if (params.cents) {
      amount = amount / 100;
    }

    if (isNaN(amount)) {
      return amount;
    }

    return '$' + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  var _default = Ember.Helper.helper(money);

  _exports.default = _default;
});