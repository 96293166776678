define("riot/components/select-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-input', 'input'],
    disabled: false,
    formattedOptions: Ember.computed('options', 'value', function () {
      if (!this.options && this.value) {
        return [{
          key: this.value,
          value: this.value
        }];
      }

      return this.options.map(function (option) {
        if (typeof option === 'string') {
          return {
            key: option,
            value: option.capitalize()
          };
        } else {
          if (option.value) {
            return option;
          } else {
            return {
              key: option.key,
              value: option.name || option.key.capitalize()
            };
          }
        }
      });
    }),
    defaultValue: Ember.on('didInsertElement', Ember.observer('value', function () {
      var selectedValue = this.$('select').val();

      if (this.value && this.value !== selectedValue) {
        this.$('select').val(this.value);
      } else if (!this.value) {
        this.set('value', selectedValue);
      }
    })),
    actions: {
      selectionChanged: function selectionChanged() {
        var value = this.$('select').val();
        this.set('value', value);

        if (this.action) {
          this.action(this.value);
        }
      }
    }
  });

  _exports.default = _default;
});