define("riot/components/smart-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['smart-dropdown'],
    classNameBindings: ['unroll', 'disabled'],
    unroll: false,

    /**
     * Expected format: [ { value, title, subtitle } ]
     */
    transformedOptions: Ember.computed('options', 'value', function () {
      var _this = this;

      if (Ember.typeOf(this.options) === 'object') {
        return Object.keys(this.options).map(function (key) {
          return {
            value: key,
            title: _this.get("options.".concat(key)),
            selected: key === _this.value
          };
        });
      } //TODO: make it work for arrays ['option 1', 'option 2'], arrays of object [ { value, title, subtitle } ]

    }),
    optionSelected: Ember.computed('transformedOptions', 'value', function () {
      return this.transformedOptions.findBy('value', this.value);
    }),
    click: function click() {
      if (!this.disabled) {
        this.toggleProperty('unroll');
      } //TODO: bind clicks outside to unroll

    },
    actions: {
      optionSelected: function optionSelected(value) {
        this.set('value', value);
      }
    }
  });

  _exports.default = _default;
});