define("riot/models/activity", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    key: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('timestamp'),
    meta: (0, _model.attr)({
      defaultValue: null
    }),
    enrollment: (0, _model.belongsTo)('enrollment'),
    attack: (0, _model.belongsTo)('attack'),
    employee: (0, _model.belongsTo)('employee'),
    company: (0, _model.belongsTo)('company')
  });

  _exports.default = _default;
});