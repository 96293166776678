define("riot/templates/components/setup-action-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VbaPFVct",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"color\",\"icon\"],[\"red\",\"exclamation\"]]]],[10,\"div\"],[14,0,\"main\"],[12],[10,\"h4\"],[14,0,\"red\"],[12],[2,\"You need to set up your account\"],[13],[10,\"p\"],[12],[2,\"Until your account is set up, all your attacks will end up in the spam folder.\"],[13],[13],[1,[30,[36,1],null,[[\"color\",\"label\",\"icon\",\"iconPosition\",\"link\"],[\"purple\",\"Set up your account\",\"solid:arrow-narrow-right\",\"right\",\"setup\"]]]]],\"hasEval\":false,\"upvars\":[\"hero-icon\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/components/setup-action-panel.hbs"
    }
  });

  _exports.default = _default;
});