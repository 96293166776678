define("riot/templates/components/radio-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JYVb/MvP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,3],null,[[\"type\",\"value\",\"name\",\"disabled\"],[\"radio\",[35,2],[35,1],[35,0]]]]],[10,\"strong\"],[12],[1,[34,4]],[13],[18,1,null]],\"hasEval\":false,\"upvars\":[\"disabled\",\"name\",\"value\",\"input\",\"label\"]}",
    "meta": {
      "moduleName": "riot/templates/components/radio-input.hbs"
    }
  });

  _exports.default = _default;
});