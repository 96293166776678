define("riot/utils/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROLES = [{
    key: 'cio-ciso-cso',
    value: '👮🏻‍♂️ CIO/CISO/CSO'
  }, {
    key: 'cto-vp-engineering',
    value: '🖲 CTO/VP Engineering'
  }, {
    key: 'network-system-admin',
    value: '🕸 Network/System Admin'
  }, {
    key: 'developer',
    value: '👩🏼‍💻 Developer (Lead, Senior, etc.)'
  }, {
    key: 'ceo-cfo-cpo-cmo',
    value: '👨🏻‍💼CEO/CFO/CPO/CMO'
  }, {
    key: 'professional',
    value: '💼 Professional (Sales, HR, Marketing, Ops, Product, etc.)'
  }, {
    key: 'reseller',
    value: '🤝 Reseller/MSP/Distributor'
  }, {
    key: 'individual',
    value: '🧑🏻 Individual/Student'
  }];
  var _default = ROLES;
  _exports.default = _default;
});