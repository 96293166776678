define("riot/routes/campaign/index/audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      var campaign = this.modelFor('campaign');
      var recipients = campaign.get('recipients') || {};

      if (recipients.employees) {
        return Ember.copy(recipients.employees); // as we will edit later on the model, we need to copy here
      }

      if (recipients.groups) {
        var groups = recipients.groups.map(function (groupId) {
          return _this.store.findRecord('group', groupId);
        });
        return Ember.RSVP.all(groups).then(function (groups) {
          return groups.reduce(function (employees, group) {
            return employees.addObjects(group.employees);
          }, []);
        });
      }

      return [];
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('employeesIds', []);
      controller.send('loadMore');
    }
  });

  _exports.default = _default;
});