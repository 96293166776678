define("riot/templates/phishing/outlook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o5zyrbAU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing outlook\"],[12],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[6,[37,3],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"img\"],[14,\"src\",\"/images/microsoft-logo.svg\"],[12],[13],[10,\"h3\"],[12],[2,\"Sign in\"],[13],[10,\"p\"],[12],[2,\"to continue to Outlook\"],[13],[10,\"label\"],[12],[1,[30,[36,4],null,[[\"value\",\"disabled\",\"placeholder\"],[[35,1,[\"email\"]],true,\"Your email address\"]]]],[13],[10,\"label\"],[12],[1,[30,[36,4],null,[[\"value\",\"type\",\"autofocus\",\"placeholder\"],[[35,1,[\"password\"]],\"password\",true,\"Password\"]]]],[13],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"label\",\"loading\"],[\"Connect\",[35,1,[\"isLoading\"]]]]]],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"if\",\"input\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/outlook.hbs"
    }
  });

  _exports.default = _default;
});