define("riot/templates/components/slide-over", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QDMSuDGh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"slider\"],[12],[1,[30,[36,1],null,[[\"icon\",\"click\"],[\"solid:x\",[30,[36,0],[[32,0],\"close\"],null]]]]],[18,1,null],[13],[10,\"div\"],[14,0,\"overlay\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"hero-icon\"]}",
    "meta": {
      "moduleName": "riot/templates/components/slide-over.hbs"
    }
  });

  _exports.default = _default;
});