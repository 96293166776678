define("riot/templates/phishing/microsoft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f9xNGSOa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing microsoft\"],[12],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"img\"],[14,\"src\",\"/images/microsoft/logo.svg\"],[14,\"width\",\"108px\"],[12],[13],[10,\"h3\"],[12],[2,\"Sign in\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"class\",\"value\",\"disabled\",\"placeholder\"],[\"mail\",[35,1,[\"email\"]],true,\"Your email address\"]]]],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"class\",\"value\",\"type\",\"autofocus\",\"placeholder\"],[\"password\",[35,1,[\"password\"]],\"password\",true,\"Password\"]]]],[13],[6,[37,4],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"label\"],[\"Keep me signed in\"]]]],[1,[30,[36,6],null,[[\"label\",\"loading\"],[\"Sign in\",[35,1,[\"isLoading\"]]]]]],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"input\",\"if\",\"checkbox-input\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/microsoft.hbs"
    }
  });

  _exports.default = _default;
});