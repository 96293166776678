define("riot/templates/components/campaign-frequency-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GB33H4Yx",
    "block": "{\"symbols\":[\"frequency\"],\"statements\":[[6,[37,13],null,[[\"icon\",\"title\",\"description\",\"action\",\"isExpanded\",\"isValid\"],[\"outline:clock\",[35,12,[\"title\"]],[35,12,[\"description\"]],[30,[36,0],[[32,0],\"save\"],null],[35,11],true]],[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"value\",\"options\"],[[35,9],[35,8]]],[[\"default\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[32,1,[\"key\"]],\"one-off\"],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,3],null,[[\"class\",\"value\",\"options\",\"action\"],[\"one-off\",[35,5],[35,4],[30,[36,0],[[32,0],\"selectionChanged\"],null]]]]]],\"parameters\":[]}]]],[6,[37,7],[[30,[36,6],[[32,1,[\"key\"]],\"recurring\"],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,3],null,[[\"class\",\"value\",\"options\",\"action\"],[\"recurring\",[35,2],[35,1],[30,[36,0],[[32,0],\"selectionChanged\"],null]]]]]],\"parameters\":[]}]]],[10,\"p\"],[12],[1,[32,1,[\"description\"]]],[13]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"recurringFrequencies\",\"recurringFrequency\",\"select-input\",\"oneOffFrequencies\",\"oneOffFrequency\",\"is-eq\",\"if\",\"options\",\"option\",\"radio-group\",\"isExpanded\",\"details\",\"campaign-setting\"]}",
    "meta": {
      "moduleName": "riot/templates/components/campaign-frequency-setting.hbs"
    }
  });

  _exports.default = _default;
});