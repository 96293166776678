define("riot/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['top-bar'],
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    showDropdown: false,
    bindClickOnDropdown: Ember.observer('showDropdown', function () {
      var _this = this;

      if (this.showDropdown) {
        $('body').bind('click', function (ev) {
          _this.set('showDropdown', false);

          $('body').unbind(ev);
        });
      }
    }),
    isOnboarding: Ember.computed('router.currentRouteName', function () {
      var route = this.router.currentRouteName;
      return route.includes('welcome');
    }),
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('showDropdown');
      }
    }
  });

  _exports.default = _default;
});