define("riot/controllers/campaign/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    parentController: Ember.inject.controller('campaign'),
    campaign: Ember.computed.alias('parentController.model'),
    breadcrumb: [{
      label: 'Campaigns',
      link: 'campaigns'
    }],
    details: Ember.computed('campaign.{_frequency,autoSendTraining}', function () {
      var frequency = this.get('campaign._frequency');
      var training = this.get('campaign.autoSendTraining') ? 'With' : 'Without';
      return [{
        label: "".concat(frequency, " campaign"),
        icon: 'solid:clock'
      }, {
        label: "".concat(training, " training"),
        icon: 'solid:chat-alt-2'
      }];
    })
  });

  _exports.default = _default;
});