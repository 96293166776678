define("riot/routes/employees/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('name', controller.keyword || '');

      if (controller.groups) {
        controller.set('groupsArray', controller.groups.split(','));
      } else {
        controller.set('groupsArray', []);
      }

      controller.set('isLoading', false);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('groups', '');
        controller.set('keyword', '');
      }
    }
  });

  _exports.default = _default;
});