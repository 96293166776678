define("riot/components/toggle-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['toggle-input'],
    classNameBindings: ['value:on-state:off-state'],
    click: function click() {
      this.toggleProperty('value');

      if (this.action) {
        this.action();
      }
    }
  });

  _exports.default = _default;
});