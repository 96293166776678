define("riot/components/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkbox-group', 'label'],
    fullsize: true,
    transformer: Ember.computed('value', 'value.length', 'options', {
      get: function get() {
        var state = {};
        var value = this.value || [];
        this.options.forEach(function (option) {
          state[option.key] = value.includes(option.key);
        });
        return state;
      }
    }),
    for: Ember.computed('fullsize', function () {
      if (!this.fullsize) {
        return 'none';
      }

      return false;
    }),
    actions: {
      clicked: function clicked(option) {
        if (this.clicked) {
          this.clicked(option);
        }
      },
      valueChanged: function valueChanged(key, value) {
        var len = this.get('value.length');

        if (value === true) {
          // checked
          this.value.addObject(key);
        } else {
          this.value.removeObject(key);
        }

        if (this.action && len !== this.get('value.length')) {
          this.action(key, value);
        }
      }
    }
  });

  _exports.default = _default;
});