define("riot/templates/components/tabs-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2BRVo7PJ",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[10,\"ul\"],[12],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"li\"],[12],[6,[37,0],null,[[\"route\"],[[32,1,[\"route\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"title\"]]]],\"parameters\":[]}]]],[13]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"link-to\",\"tabs\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "riot/templates/components/tabs-nav.hbs"
    }
  });

  _exports.default = _default;
});