define("riot/templates/components/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ed2AjY9",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[10,\"fieldset\"],[15,1,[34,1]],[12],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"label\",\"disabled\",\"value\",\"name\",\"checked\"],[[32,1,[\"name\"]],[35,2],[32,1,[\"key\"]],[35,1],[30,[36,0],[[32,0],\"valueChanged\"],null]]],[[\"default\"],[{\"statements\":[[18,2,[[32,1]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"id\",\"disabled\",\"radio-input\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "riot/templates/components/radio-group.hbs"
    }
  });

  _exports.default = _default;
});