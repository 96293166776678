define("riot/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    setupController: function setupController() {
      this.notify.create("An unexpected error occured", 'error');
      this.replaceWith('index');
    }
  });

  _exports.default = _default;
});