define("riot/utils/isFreeEmail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isFreeEmail = function isFreeEmail(email) {
    return email.indexOf('gmail.com') !== -1 || email.indexOf('hotmail.com') !== -1;
  };

  var _default = isFreeEmail;
  _exports.default = _default;
});