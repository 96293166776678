define("riot/templates/phishing/atlassian", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+z3Ftr5d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing atlassian\"],[12],[10,\"img\"],[14,\"src\",\"/images/atlassian/logo.png\"],[14,\"width\",\"240px\"],[14,0,\"logo\"],[12],[13],[10,\"section\"],[12],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"h1\"],[12],[2,\"Log in to your account\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"disabled\",\"placeholder\"],[[35,1,[\"email\"]],true,\"Email address\"]]]],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"value\",\"type\",\"autofocus\",\"placeholder\"],[[35,1,[\"password\"]],\"password\",true,\"Enter password\"]]]],[13],[6,[37,4],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"label\",\"loading\"],[\"Sign In\",[35,1,[\"isLoading\"]]]]]],[13],[13],[13],[10,\"footer\"],[12],[10,\"hr\"],[12],[13],[10,\"img\"],[14,\"src\",\"/images/atlassian/logo-grey.svg\"],[14,\"width\",\"190px\"],[12],[13],[10,\"p\"],[12],[2,\"One account for Jira, Confluence, Trello and more.\"],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"input\",\"if\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/atlassian.hbs"
    }
  });

  _exports.default = _default;
});