define("riot/templates/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7l6ifcGO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"title\"],[\"Your employees\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],null,[[\"color\",\"icon\",\"label\",\"link\"],[\"gray\",\"sort-descending\",\"Import from CSV\",\"employees.import\"]]]],[1,[30,[36,0],null,[[\"color\",\"icon\",\"label\",\"link\"],[\"purple\",\"plus\",\"New employee\",\"employees.new\"]]]]],\"parameters\":[]}]]],[10,\"main\"],[12],[1,[30,[36,2],null,[[\"resource\"],[\"employee\"]]]],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[13]],\"hasEval\":false,\"upvars\":[\"smart-button\",\"header-bar\",\"resource-table\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "riot/templates/employees.hbs"
    }
  });

  _exports.default = _default;
});