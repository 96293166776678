define("riot/components/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['radio-group'],
    disabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.id) {
        this.set('id', Ember.guidFor(this));
      }
    },
    valueChanged: Ember.on('didInsertElement', Ember.observer('value', function () {
      var value = this.value;

      if (value) {
        this.$("input[value='".concat(value, "']")).prop('checked', true);
      }
    })),
    actions: {
      valueChanged: function valueChanged(key) {
        this.set('value', key);

        if (this.action) {
          this.action(key);
        }
      }
    }
  });

  _exports.default = _default;
});