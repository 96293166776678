define("riot/templates/components/campaign-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aV8eQXlp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"metrics\"],[12],[10,\"li\"],[14,0,\"badge-container\"],[12],[10,\"div\"],[14,0,\"metric\"],[12],[10,\"div\"],[15,0,[31,[\"badge \",[34,0,[\"statusColor\"]]]]],[12],[1,[30,[36,4],[[35,0,[\"status\"]]],null]],[13],[13],[13],[10,\"li\"],[14,0,\"main-metric\"],[12],[10,\"div\"],[14,0,\"metric\"],[12],[6,[37,3],[[35,0,[\"isCompleted\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"p\"],[12],[2,\"Last attack\"],[13],[10,\"strong\"],[14,0,\"large primary\"],[12],[1,[30,[36,2],null,[[\"date\"],[[35,1,[\"firstObject\",\"createdAt\"]]]]]],[13]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0,[\"isDraft\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"p\"],[12],[2,\"Next attack\"],[13],[10,\"strong\"],[14,0,\"large primary\"],[12],[2,\"unplanned\"],[13]],\"parameters\":[]},{\"statements\":[[10,\"p\"],[12],[2,\"Next attack\"],[13],[10,\"strong\"],[14,0,\"large primary\"],[12],[1,[30,[36,2],null,[[\"date\"],[[35,1]]]]],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[13],[10,\"li\"],[14,0,\"aside\"],[12],[10,\"div\"],[14,0,\"metric\"],[12],[10,\"p\"],[12],[2,\"Audience\"],[13],[10,\"strong\"],[14,0,\"large\"],[12],[6,[37,5],null,[[\"route\"],[\"campaign.index.audience\"]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"audience\"]]]],\"parameters\":[]}]]],[13],[13],[13],[13],[1,[30,[36,6],null,[[\"value\"],[[35,0,[\"statistics\"]]]]]]],\"hasEval\":false,\"upvars\":[\"campaign\",\"date\",\"countdown\",\"if\",\"capitalize\",\"link-to\",\"statistics-waterfall\"]}",
    "meta": {
      "moduleName": "riot/templates/components/campaign-statistics.hbs"
    }
  });

  _exports.default = _default;
});