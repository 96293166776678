define("riot/controllers/train", ["exports", "riot/chats/training/phishing"], function (_exports, _phishing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['key'],
    isLoading: true,
    run: function run() {
      this.set('upcoming', []);
      this.upcoming.addObjects(_phishing.default);
    }
  });

  _exports.default = _default;
});