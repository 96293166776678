define("riot/templates/components/variable-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0jiwI4Dk",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"description\"],[12],[1,[30,[36,4],null,[[\"icon\"],[\"arrow-narrow-right\"]]]],[1,[35,5,[\"description\"]]],[13],[6,[37,7],[[30,[36,6],[[35,5,[\"type\"]],\"static\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],null,[[\"value\",\"autoselect\",\"placeholder\",\"search\",\"select\",\"items\"],[[35,2],true,\"Enter a value for this variable…\",[30,[36,1],[[32,0],\"search\"],null],[30,[36,1],[[32,0],\"select\"],null],[35,0]]],[[\"default\"],[{\"statements\":[[1,[32,1]]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[10,\"p\"],[12],[2,\"This will be set automatically when the email will be sent.\"],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"items\",\"action\",\"value\",\"autocomplete-input\",\"hero-icon\",\"selection\",\"is-eq\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/components/variable-popover.hbs"
    }
  });

  _exports.default = _default;
});