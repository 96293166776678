define("riot/components/tags-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tags-input', 'input', 'fake'],
    classNameBindings: ['focus'],
    // WIP
    focusIn: function focusIn() {
      this.set('focus', true);
    },
    focusOut: function focusOut() {
      this.set('focus', false);
    }
  });

  _exports.default = _default;
});