define("riot/routes/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    segment: Ember.inject.service(),
    setupController: function setupController() {
      this.session.invalidate();
      this.segment.logout();
    }
  });

  _exports.default = _default;
});