define("riot/components/campaign-templates-setting", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['campaign-templates-setting'],
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    localVariables: null,
    settings: null,
    // the updated value
    selectedTemplates: null,
    // the list of selected templates (array)
    isDirty: false,
    init: function init() {
      var _this = this;

      var value = this.value;
      var localVariables = {};
      var templates = this.store.query('template', {
        query: function query(ref) {
          return ref.where('status', '==', 'active');
        }
      });

      this._super.apply(this, arguments);

      this.set('templates', templates);
      this.set('settings', {});
      this.set('selectedTemplates', []);

      if (value) {
        Object.keys(value).forEach(function (templateName) {
          Object.keys(value[templateName]).forEach(function (service) {
            var template = "".concat(templateName, "/").concat(service);

            _this.selectedTemplates.addObject(template);

            localVariables[template] = value[templateName][service].variables;
          });
        });

        if (this.isSingle) {
          this.set('templateName', this.selectedTemplates.firstObject);
        }

        this.set('isExpanded', false);
      }

      this.set('localVariables', localVariables);
    },
    getVariableOptions: function getVariableOptions(variable, template, defaultValue) {
      var options = [defaultValue];
      var defaultVariables = this.get("currentUser.company.templatesDefaultVariables.".concat(variable));

      if (defaultVariables) {
        if (defaultVariables[template]) {
          options.unshiftObject(defaultVariables[template]);
        }

        Object.keys(defaultVariables).forEach(function (t) {
          options.addObject(defaultVariables[t]);
        });
      }

      return options;
    },
    variables: Ember.computed('templates.length', 'currentUser.company.templatesDefaultVariables', 'localVariables', function () {
      var _this2 = this;

      var variables = {};

      if (this.templates.length) {
        this.templates.forEach(function (template) {
          var templateVariables = Object.keys(template.variables).reduce(function (acc, variable) {
            var options = _this2.getVariableOptions(variable, template.id, template.variables[variable].defaultValue);

            acc[variable] = {
              description: template.variables[variable].description,
              options: options,
              value: options.firstObject
            };
            return acc;
          }, {});

          if (!template.services || template.services.length === 0) {
            variables["".concat(template.id, "/plainText")] = templateVariables;
          } else {
            template.services.forEach(function (service) {
              variables["".concat(template.id, "/").concat(service)] = templateVariables;
            });
          }
        });
      }

      Object.keys(variables).forEach(function (template) {
        Object.keys(variables[template]).forEach(function (variable) {
          if (_this2.get("localVariables.".concat(template, ".").concat(variable))) {
            variables[template][variable].value = _this2.get("localVariables.".concat(template, ".").concat(variable));
          }
        });
      });
      return variables;
    }),
    currentVariables: Ember.computed('preview.templateName', 'variables', function () {
      if (this.preview) {
        return this.get("variables.".concat(this.preview.templateName));
      }
    }),
    options: Ember.computed('templates.length', function () {
      var options = [];
      this.templates.forEach(function (template) {
        if (template.get('hasServices')) {
          template.get('services').forEach(function (service) {
            options.addObject({
              key: "".concat(template.get('id'), "/").concat(service),
              name: "".concat(service.capitalize(), ": ").concat(template.get('name')),
              description: template.get('description'),
              template: template
            });
          });
        } else {
          options.addObject({
            key: "".concat(template.get('id'), "/plainText"),
            name: template.get('name'),
            description: template.get('description'),
            template: template
          });
        }
      });
      return options;
    }),
    isSingle: Ember.computed.equal('audience', 1),
    details: Ember.computed('isExpanded', 'templates.length', 'value', 'audience', function () {
      var _this3 = this;

      var details = {
        title: 'Select the templates',
        description: 'Specify which attacks should be sent to the selected employees'
      };
      var templates = Object.keys(this.value === undefined ? {} : this.value);
      var audience = this.audience;
      var count = 0;
      templates.forEach(function (template) {
        count += Object.keys(_this3.get("value.".concat(template))).length;
      });

      if (audience === 1) {
        details.title = 'Select the template';
        details.description = 'Specify which attack should be sent to the selected employees';
      }

      if (!this.isExpanded) {
        if (count === 1 && this.get('templates.length')) {
          var template = this.templates.findBy('id', templates.get('firstObject'));
          var _service = Object.keys(this.value[templates.get('firstObject')]).firstObject;
          details.title = "".concat(template.get('name'), " template");

          if (_service !== 'plainText') {
            details.title = "".concat(_service.capitalize(), ": ").concat(details.title);
          }

          details.description = "All attacks will be based on the ".concat(templates.get('firstObject').capitalize(), " template");
        } else if (count > 1) {
          details.title = "".concat(count, " templates");
          details.description = "Attacks will randomly be based on one of the ".concat(templates.get('length'), " selected templates");
        }
      }

      return details;
    }),
    isValid: Ember.computed.bool('selectedTemplates.length'),
    // For the radio input
    template: Ember.computed('selectedTemplates.firstObject', 'audience', {
      set: function set(key, value) {
        this.set('selectedTemplates', [value]);
        return value;
      },
      get: function get() {
        return this.selectedTemplates.firstObject;
      }
    }),
    preview: Ember.computed('templateName', 'templates.length', function () {
      var preview = null;

      if (this.templateName && this.templates.length) {
        var _this$templateName$sp = this.templateName.split('/'),
            _this$templateName$sp2 = (0, _slicedToArray2.default)(_this$templateName$sp, 2),
            key = _this$templateName$sp2[0],
            _service2 = _this$templateName$sp2[1];

        preview = {
          templateName: this.templateName,
          template: this.templates.findBy('id', key),
          service: _service2
        };
      }

      return preview;
    }),
    actions: {
      update: function update() {
        var _this4 = this;

        var settings = {};
        this.selectedTemplates.forEach(function (templateName) {
          var _templateName$split = templateName.split('/'),
              _templateName$split2 = (0, _slicedToArray2.default)(_templateName$split, 2),
              key = _templateName$split2[0],
              service = _templateName$split2[1];

          var variables = Object.keys(_this4.variables[templateName]).reduce(function (acc, variable) {
            acc[variable] = _this4.variables[templateName][variable].value;
            return acc;
          }, {});

          if (settings[key] === undefined) {
            settings[key] = {};
          }

          settings[key][service] = {
            variables: variables
          };
        });
        this.set('settings', settings);
        this.set('isDirty', true);
      },
      save: function save() {
        if (this.isDirty) {
          this.set('value', this.settings);

          if (this.save) {
            this.save('settings');
          }
        }
      },
      setPreview: function setPreview(value) {
        var templateName = value.key;
        this.set('templateName', templateName);
      },
      setTemplate: function setTemplate(key) {
        this.send('setPreview', {
          key: key
        });
        this.send('update');
      },
      setVariable: function setVariable(variable, value) {
        if (!this.get("localVariables.".concat(this.preview.templateName))) {
          this.set("localVariables.".concat(this.preview.templateName), {});
        }

        this.set("localVariables.".concat(this.preview.templateName, ".").concat(variable), value);
        this.notifyPropertyChange('variables');
        this.send('update');
      }
    }
  });

  _exports.default = _default;
});