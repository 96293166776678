define("riot/services/notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: Ember.A([]),
    create: function create(notification, type) {
      if (typeof notification === 'string') {
        notification = {
          message: notification
        };
      }

      if (type) {
        notification.type = type;
      }

      this.notifications.addObject(notification);
    },
    error: function error(err) {
      var message = 'An unknown error occured';

      if (err.message) {
        message = err.message;
      }

      if (typeof err === 'string') {
        message = err;
      }

      return this.create({
        message: message,
        type: 'error'
      });
    }
  });

  _exports.default = _default;
});