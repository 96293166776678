define("riot/templates/campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hqClZaid",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"title\"],[\"Campaigns\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],null,[[\"color\",\"icon\",\"label\",\"link\"],[\"purple\",\"plus\",\"New campaign\",\"campaigns.new\"]]]]],\"parameters\":[]}]]],[10,\"main\"],[12],[1,[30,[36,3],null,[[\"resource\",\"link\"],[\"campaign\",[30,[36,2],[[32,0],\"link\"],null]]]]],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[13]],\"hasEval\":false,\"upvars\":[\"smart-button\",\"header-bar\",\"action\",\"resource-table\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "riot/templates/campaigns.hbs"
    }
  });

  _exports.default = _default;
});