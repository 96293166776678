define("riot/utils/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    password: function password(size) {
      var password = '';

      while (password.substr(0, size).length !== size) {
        password += Math.random().toString(36).substring(7);
      }

      return password;
    }
  };
  _exports.default = _default;
});