define("riot/models/company", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // read only
    createdAt: (0, _model.attr)('timestamp'),
    domain: (0, _model.attr)('string'),
    creator: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    provider: (0, _model.attr)('string'),
    statistics: (0, _model.attr)(),
    templatesDefaultVariables: (0, _model.attr)(),
    // write if creator
    name: (0, _model.attr)('string'),
    users: (0, _model.attr)(),
    // write
    settings: (0, _model.attr)(),
    meta: (0, _model.attr)(),
    billing: (0, _model.attr)(),
    industry: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    size: (0, _model.attr)('string'),
    // related
    employees: (0, _model.hasMany)('employee'),
    // computed properties
    isNew: Ember.computed.equal('status', 'new'),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive'),
    isGoogle: Ember.computed.equal('provider', 'google'),
    isMicrosoft: Ember.computed.equal('provider', 'outlook'),
    $billing: Ember.computed('billing.{name,address,city,zipCode,country}', function () {
      var _this = this;

      var attributes = ['name', 'address', 'city', 'zipCode', 'country'].map(function (attribute) {
        return _this.get("billing.".concat(attribute));
      }).filter(function (attribute) {
        return !Ember.isEmpty(attribute);
      });

      if (attributes.length === 0) {
        return 'No billing address';
      }

      return attributes.join(', ');
    })
  });

  _exports.default = _default;
});