define("riot/routes/train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function removeCharAt(value, index) {
    return value.substr(0, index) + value.substr(index + 1);
  }

  function fakeDomain(domain) {
    var fakes = {};
    var changes = {
      i: '',
      l: '1',
      1: 'l'
    };
    var changed = false;
    fakes.tld = domain.replace(/\.(.+)$/, '.co');
    fakes.name = domain.split('.').map(function (value, key) {
      var initialValue = value;

      if (!changed && key !== domain.split('.').length - 1) {
        Object.keys(changes).forEach(function (change) {
          if (!changed) {
            value = value.replace(change, changes[change]);
            changed = value !== initialValue;
          }
        });

        if (!changed) {
          value = value.replace(/(\w)\1+/, '$1'); // google -> gogle

          changed = value !== initialValue;
        }

        if (!changed) {
          value = removeCharAt(value, Math.round(value.length / 2));
          changed = true;
        }
      }

      return value;
    }).join('.');

    if (fakes.tld === domain) {
      // domain is a .co
      fakes.tld = domain.replace(/\.(.+)$/, '.net');
    }

    return fakes;
  }

  var _default = Ember.Route.extend({
    api: Ember.inject.service(),
    model: function model(params) {
      return this.api.enrollment(params.id, params.key);
    },
    setupController: function setupController(controller, model) {
      var enrollment = this.store.createRecord('enrollment', model.enrollment);
      controller.set('currentUser', Ember.Object.create({
        user: model.employee,
        company: model.company,
        enrollment: enrollment
      }));
      controller.set('currentUser.company.fakeDomain', fakeDomain(controller.get('currentUser.company.domain')));
      controller.run();
      controller.set('isLoading', false);
    }
  });

  _exports.default = _default;
});