define("riot/models/group", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('timestamp'),
    creator: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    employees: (0, _model.attr)(),
    company: (0, _model.belongsTo)('company')
  });

  _exports.default = _default;
});