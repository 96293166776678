define("riot/templates/phishing/slack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ORlWmGUX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing slack\"],[12],[10,\"header\"],[12],[10,\"img\"],[14,\"src\",\"/images/slack-logo.svg\"],[14,\"width\",\"106px\"],[12],[13],[13],[10,\"section\"],[12],[6,[37,2],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[11,\"form\"],[4,[38,3],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"div\"],[14,0,\"content\"],[12],[10,\"h3\"],[12],[2,\"Sign in to Slack\"],[13],[10,\"p\"],[12],[2,\"Enter your \"],[10,\"strong\"],[12],[2,\"email address\"],[13],[2,\" and  \"],[10,\"strong\"],[12],[2,\"password.\"],[13],[13],[10,\"label\"],[12],[1,[30,[36,4],null,[[\"value\",\"disabled\",\"placeholder\"],[[35,1,[\"email\"]],true,\"you@example.com\"]]]],[13],[10,\"label\"],[12],[1,[30,[36,4],null,[[\"value\",\"type\",\"autofocus\",\"placeholder\"],[[35,1,[\"password\"]],\"password\",true,\"Password\"]]]],[13],[10,\"label\"],[12],[1,[30,[36,5],null,[[\"label\",\"loading\"],[\"Sign in\",[35,1,[\"isLoading\"]]]]]],[13],[1,[30,[36,6],null,[[\"label\"],[\"Remember me\"]]]],[13],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"if\",\"action\",\"input\",\"smart-button\",\"checkbox-input\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/slack.hbs"
    }
  });

  _exports.default = _default;
});