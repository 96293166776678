define("riot/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['notification'],
    classNameBindings: ['notification.type'],
    timer: 0,
    pace: 8,
    // Number of characters people read per second
    notify: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.run();
    },
    run: function run() {
      var e = Ember.run.later(this, function () {
        this.incrementProperty('timer');

        if (this.timer <= this.delay) {
          this.run();
        } else {
          this.get('notify.notifications').removeObject(this.notification);
        }
      }, 1000);
      this.set('e', e);
    },
    delay: Ember.computed('notification.delay', 'notification.message.length', 'pace', function () {
      var delay = this.get('notification.delay');

      if (Ember.isEmpty(delay)) {
        delay = Math.floor(this.get('notification.message.length') / this.pace);
      }

      return delay;
    }),
    style: Ember.computed('timer', 'delay', function () {
      return Ember.String.htmlSafe("width: ".concat(Math.round(this.timer * 100 / this.delay), "%"));
    }),
    actions: {
      close: function close() {
        if (this.e) {
          Ember.run.cancel(this.e);
        }

        this.get('notify.notifications').removeObject(this.notification);
      }
    }
  });

  _exports.default = _default;
});