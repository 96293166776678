define("riot/components/variable-popover", ["exports", "jquery", "riot/utils/keys"], function (_exports, _jquery, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['popover'],
    classNameBindings: ['selection.type', 'selection:show:hide', 'leftOrRight:left:right'],
    attributeBindings: ['style'],
    items: Ember.computed('selection.options', 'value', function () {
      return this.get('selection.options').without(this.value);
    }),
    leftOrRight: Ember.computed('selection.id', function () {
      var size = (0, _jquery.default)('.email-preview').width();
      var el = (0, _jquery.default)('.email-preview iframe').contents().find("#".concat(this.get('selection.id'))).first();
      var left = false;

      if (Math.round(size / 2) < Math.round((el.offset().left + el.outerWidth()) * 0.8)) {
        left = true;
      }

      return left;
    }),
    style: Ember.computed('selection.id', 'fromTop', 'leftOrRight', function () {
      var el = (0, _jquery.default)('.email-preview iframe').contents().find("#".concat(this.selection.id)).first();
      var position = el.offset(); // We're scaling the iframe by 20%, so we need to take this into account here

      position.top = Math.round(position.top * 0.8) - 1 - Math.round(this.fromTop * 0.8);

      if (this.leftOrRight) {
        position.left = Math.round(position.left * 0.8 - 300); // variable-popover is 300px width
      } else {
        position.left = Math.round(position.left * 0.8 + el.outerWidth() * 0.8 + 30);
      }

      return "top: ".concat(position.top, "px; left: ").concat(position.left, "px;");
    }),
    keyDown: function keyDown(e) {
      var key = e.which;

      if (key === _keys.default.esc) {
        this.set('selection', null);
      } else if (key === _keys.default.enter) {
        this.send('select', this.value);
      }
    },
    click: function click() {
      this.$('input').select();
    },
    actions: {
      search: function search(keyword) {
        keyword = keyword.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        return this.get('selection.options').without(this.value).filter(function (option) {
          option = option.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
          return option.match(keyword);
        });
      },
      select: function select(value) {
        this.setVariable(value);
      }
    }
  });

  _exports.default = _default;
});