define("riot/templates/components/array-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d6EobR3C",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[10,\"dt\"],[14,0,\"with-input\"],[12],[1,[30,[36,12],null,[[\"resource\",\"action\",\"excludes\",\"searchFilter\"],[[35,2],[30,[36,0],[[32,0],\"add\"],null],[35,11],\"status:active\"]]]],[13]],\"parameters\":[]}]]],[6,[37,6],[[35,8,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"dt\"],[12],[10,\"span\"],[12],[1,[32,1,[\"name\"]]],[6,[37,6],[[30,[36,5],[[32,1,[\"status\"]],\"inactive\"],null]],null,[[\"default\"],[{\"statements\":[[10,\"small\"],[12],[2,\"(Deactivated)\"],[13]],\"parameters\":[]}]]],[6,[37,6],[[30,[36,5],[[35,2],\"group\"],null]],null,[[\"default\"],[{\"statements\":[[10,\"small\"],[12],[1,[32,1,[\"employees\",\"length\"]]],[2,\" \"],[1,[30,[36,4],[[32,1,[\"employees\",\"length\"]],\"employee\"],null]],[13]],\"parameters\":[]}]]],[13],[6,[37,6],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[11,\"a\"],[4,[38,0],[[32,0],\"remove\",[32,1]],[[\"on\"],[\"click\"]]],[12],[2,\"remove\"],[13]],\"parameters\":[]},{\"statements\":[[6,[37,3],null,[[\"route\",\"model\"],[[35,2],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"view\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[6,[37,6],[[35,13]],null,[[\"default\"],[{\"statements\":[[10,\"dt\"],[14,0,\"load-more\"],[12],[1,[30,[36,1],null,[[\"icon\",\"color\",\"label\",\"action\"],[\"cheveron-down\",\"white\",\"load more\",[30,[36,0],[[32,0],\"loadMore\"],null]]]]],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"smart-button\",\"resource\",\"link-to\",\"pluralize\",\"is-eq\",\"if\",\"editable\",\"items\",\"-track-array\",\"each\",\"value\",\"algolia-input\",\"hasMore\"]}",
    "meta": {
      "moduleName": "riot/templates/components/array-input.hbs"
    }
  });

  _exports.default = _default;
});