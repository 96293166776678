define("riot/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nDgv4mKE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"bar\"],[12],[10,\"div\"],[15,5,[34,0]],[14,0,\"current\"],[12],[13],[13],[18,1,null]],\"hasEval\":false,\"upvars\":[\"style\"]}",
    "meta": {
      "moduleName": "riot/templates/components/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});