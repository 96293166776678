define("riot/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qWf6sUTP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login-page\"],[12],[10,\"img\"],[14,\"src\",\"/images/logo-purple.svg\"],[14,0,\"logo\"],[12],[13],[10,\"h2\"],[14,0,\"centered\"],[12],[2,\"Log in to your account\"],[13],[10,\"p\"],[14,0,\"centered\"],[12],[2,\"or \"],[6,[37,4],null,[[\"route\"],[\"signup\"]],[[\"default\"],[{\"statements\":[[2,\"create a new account\"]],\"parameters\":[]}]]],[13],[10,\"div\"],[14,0,\"card\"],[12],[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"loader\"],[12],[2,\"Please wait…\"],[13]],\"parameters\":[]},{\"statements\":[[1,[30,[36,1],null,[[\"color\",\"label\",\"action\",\"customIcon\",\"class\"],[\"white\",\"Continue with Google\",[30,[36,0],[[32,0],\"loginWithGoogle\"],null],\"/images/google-icon.svg\",\"google-button\"]]]],[1,[30,[36,1],null,[[\"color\",\"label\",\"action\",\"customIcon\",\"class\"],[\"white\",\"Continue with Microsoft 365\",[30,[36,0],[[32,0],\"loginWithMicrosoft\"],null],\"/images/microsoft-icon.svg\",\"microsoft-button\"]]]],[10,\"p\"],[14,0,\"or\"],[12],[2,\"or\"],[13],[11,\"form\"],[4,[38,0],[[32,0],\"loginWithEmail\"],[[\"on\"],[\"submit\"]]],[12],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Your work email address\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,3],null,[[\"id\",\"value\",\"placeholder\",\"autofocus\"],[\"email\",[35,2],\"john@nolink.co\",true]]]],[13],[13],[1,[30,[36,1],null,[[\"color\",\"label\",\"icon\",\"iconPosition\"],[\"purple\",\"Continue with email\",\"arrow-narrow-right\",\"right\"]]]],[13]],\"parameters\":[]}]]],[13],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"smart-button\",\"email\",\"input\",\"link-to\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/login.hbs"
    }
  });

  _exports.default = _default;
});