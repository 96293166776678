define("riot/controllers/phishing/adp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentController: Ember.inject.controller('phishing')
  });

  _exports.default = _default;
});