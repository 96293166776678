define("riot/templates/phishing/icloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g2DTmDoA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing icloud\"],[12],[10,\"header\"],[12],[10,\"img\"],[14,\"src\",\"/images/icloud/apple-icon.svg\"],[14,\"width\",\"16px\"],[12],[13],[13],[10,\"section\"],[12],[10,\"img\"],[14,\"src\",\"/images/icloud/logo.png\"],[14,\"width\",\"100px\"],[12],[13],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"h1\"],[12],[2,\"Sign in to iCloud\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"class\",\"value\",\"disabled\",\"placeholder\"],[\"mail\",[35,1,[\"email\"]],true,\"Apple ID\"]]]],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"class\",\"value\",\"type\",\"autofocus\",\"placeholder\"],[\"password\",[35,1,[\"password\"]],\"password\",true,\"Password\"]]]],[13],[6,[37,4],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"button\"],[14,0,\"submit\"],[12],[10,\"img\"],[14,\"src\",\"/images/icloud/arrow-right.png\"],[14,\"width\",\"24px\"],[12],[13],[13],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"label\"],[\"Keep me signed in\"]]]],[13],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"input\",\"if\",\"checkbox-input\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/icloud.hbs"
    }
  });

  _exports.default = _default;
});