define("riot/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['progress-bar'],
    percentage: Ember.computed('total', 'current', function () {
      return Math.round(this.current * 100 / this.total);
    }),
    style: Ember.computed('percentage', function () {
      return Ember.String.htmlSafe("width:".concat(this.percentage, "%"));
    })
  });

  _exports.default = _default;
});