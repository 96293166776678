define("riot/templates/components/smart-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ri+uO5l/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,7],[[35,6],[35,3]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],null,[[\"route\",\"model\"],[[35,3],[35,6]]],[[\"default\"],[{\"statements\":[[2,\" \"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"route\"],[[35,3]]],[[\"default\"],[{\"statements\":[[2,\" \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,5],[[35,1]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,2],null,[[\"icon\"],[[35,1]]]]]],\"parameters\":[]}]]],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"img\"],[15,\"src\",[34,0]],[12],[13]],\"parameters\":[]}]]],[1,[34,8]]],\"hasEval\":false,\"upvars\":[\"customIcon\",\"icon\",\"hero-icon\",\"link\",\"link-to\",\"if\",\"resource\",\"and\",\"label\"]}",
    "meta": {
      "moduleName": "riot/templates/components/smart-button.hbs"
    }
  });

  _exports.default = _default;
});