define("riot/services/poller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.tasks = this.tasks || {};
    },
    run: function run(name, func, timer, context) {
      if (!this.get("tasks.".concat(name))) {
        this.set("tasks.".concat(name), {
          func: Ember.run.bind(context, func),
          timer: timer
        });
        this.task(name);
      }
    },
    task: function task(name) {
      var task = this.get("tasks.".concat(name));

      if (task) {
        Ember.run.later(this, function () {
          if (this.get("tasks.".concat(name))) {
            task.func();
          }

          this.task(name);
        }, task.timer);
      }
    },
    cancel: function cancel(name) {
      if (this.get("tasks.".concat(name))) {
        this.set("tasks.".concat(name), null);
      }
    }
  });

  _exports.default = _default;
});