define("riot/components/campaign-frequency-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['campaign-frequency-setting'],
    classNameBindings: ['option', 'oneOffFrequency', 'recurringFrequency'],
    init: function init() {
      var frequency = this.frequency;
      var type = this.type;

      this._super.apply(this, arguments);

      if (frequency && type) {
        this.set('option', type);

        if (type === 'one-off') {
          this.set('oneOffFrequency', frequency);
        } else {
          this.set('recurringFrequency', frequency);
        }

        this.set('isExpanded', false);
      } else {
        this.set('option', 'one-off');
        this.set('oneOffFrequency', 'once');
        this.set('recurringFrequency', 'monthly');
      }
    },
    details: Ember.computed('isExpanded', 'frequency', 'type', function () {
      var details = {
        title: 'Pick a campaign type',
        description: 'Choose when do you want to launch the attacks'
      };
      var type = this.type;

      if (!this.isExpanded) {
        if (type === 'one-off') {
          details.title = 'One-off campaign';
          details.description = 'All the attacks will be sent at once to selected employees when the campaign goes live';

          if (this.frequency === 'weekly') {
            details.description = 'All the attacks will be sent to selected employees over the next 7 days when the campaign goes live';
          }
        } else if (type === 'recurring') {
          details.title = "".concat(this.frequency.capitalize(), " campaign");
          details.description = "Every selected employees will receive one attack on a ".concat(this.frequency, " basis");
        }
      }

      return details;
    }),
    options: [{
      key: 'one-off',
      name: 'One-off sent',
      description: "Send all the attacks to the recipients at the same time, when the campaign starts."
    }, {
      key: 'recurring',
      name: 'Every',
      description: "Schedule the campaign to be sent at regular intervales. Each recipients will get one phishing test in specified period."
    }],
    oneOffFrequencies: [{
      key: 'once',
      value: 'now'
    }, {
      key: 'weekly',
      value: 'in the next 7 days'
    }],
    recurringFrequencies: [{
      key: 'monthly',
      value: 'month'
    }, {
      key: 'quarterly',
      value: 'quarter'
    }, {
      key: 'yearly',
      value: 'year'
    }],
    isDirty: Ember.computed('oneOffFrequency', 'frequency', 'type', 'option', 'recurringFrequency', function () {
      if (this.type !== this.option) {
        return true;
      } else if (this.option === 'one-off' && this.frequency !== this.oneOffFrequency) {
        return true;
      } else if (this.option === 'recurring' && this.frequency !== this.recurringFrequency) {
        return true;
      }

      return false;
    }),
    actions: {
      selectionChanged: function selectionChanged(value) {
        if (['once', 'weekly'].includes(value) && this.option !== 'one-off') {
          this.set('option', 'one-off');
        } else if (['monthly', 'quarterly', 'yearly'].includes(value) && this.option !== 'recurring') {
          this.set('option', 'recurring');
        }
      },
      save: function save() {
        var type = this.option;

        if (this.isDirty) {
          this.set('type', this.option);

          if (type === 'one-off') {
            this.set('frequency', this.oneOffFrequency);
          } else if (type === 'recurring') {
            this.set('frequency', this.recurringFrequency);
          }

          if (this.save) {
            this.save('frequency');
          }
        }
      }
    }
  });

  _exports.default = _default;
});