define("riot/templates/components/training-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RWTu67em",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"window\"],[12],[10,\"div\"],[14,0,\"dot\"],[12],[13],[10,\"div\"],[14,0,\"dot\"],[12],[13],[10,\"div\"],[14,0,\"dot\"],[12],[13],[13]],\"parameters\":[]}]]],[1,[30,[36,5],null,[[\"messages\",\"upcoming\",\"currentUser\"],[[35,4],[35,3],[35,2]]]]]],\"hasEval\":false,\"upvars\":[\"window\",\"if\",\"currentUser\",\"upcoming\",\"messages\",\"on-chat\"]}",
    "meta": {
      "moduleName": "riot/templates/components/training-preview.hbs"
    }
  });

  _exports.default = _default;
});