define("riot/components/dashboard-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['statistics'],
    currentUser: Ember.inject.service(),
    averageTest: Ember.computed.alias('currentUser.company.statistics.current.attacks.frequency'),
    nbEmployees: Ember.computed.alias('currentUser.company.statistics.current.employees.total'),
    nbEmployeesAtRisk: Ember.computed.alias('currentUser.company.statistics.current.employees.atRisk')
  });

  _exports.default = _default;
});