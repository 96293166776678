define("riot/models/template", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('timestamp'),
    name: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    types: (0, _model.attr)(),
    variables: (0, _model.attr)(),
    statistics: (0, _model.attr)(),
    services: (0, _model.attr)(),
    hasServices: Ember.computed('services.length', function () {
      return Ember.isArray(this.services) && this.get('services.length');
    })
  });

  _exports.default = _default;
});