define("riot/transforms/timestamp", ["exports", "@babel/runtime/helpers/esm/typeof", "@ember-data/serializer/transform", "firebase/app"], function (_exports, _typeof2, _transform, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if ((0, _typeof2.default)(serialized) === 'object' && serialized !== null) {
        return serialized.toDate();
      }

      return null;
    },
    serialize: function serialize(deserialized) {
      if (deserialized instanceof Date) {
        return _app.default.firestore.Timestamp.fromDate(deserialized);
      }

      return null;
    }
  });

  _exports.default = _default;
});