define("riot/templates/campaign/index/audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NK3Dgtb6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],null,[[\"close\"],[[30,[36,3],[[32,0],\"toggleAudience\"],null]]],[[\"default\"],[{\"statements\":[[10,\"section\"],[14,0,\"audience\"],[12],[10,\"h3\"],[12],[2,\"Audience\"],[13],[10,\"p\"],[12],[10,\"strong\"],[12],[1,[35,0,[\"audience\"]]],[1,[30,[36,1],[[35,0,[\"audience\"]],\" employee\"],null]],[13],[2,\" \"],[1,[30,[36,1],[[35,0,[\"audience\"]],\"is\"],null]],[2,\" part of this campaign. \"],[6,[37,2],[[35,0,[\"audience\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,0,[\"recipients\",\"company\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"The whole company has been selected\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"recipients\",\"employees\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"They were specifically selected\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"recipients\",\"groups\"]]],null,[[\"default\"],[{\"statements\":[[2,\"All employees from \"],[1,[35,0,[\"recipients\",\"groups\",\"length\"]]],[1,[30,[36,1],[[35,0,[\"recipients\",\"groups\",\"length\"]],\" group\"],null]],[2,\" were selected\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\" to be targeted by this campaign.\"]],\"parameters\":[]}]]],[13],[10,\"label\"],[12],[1,[30,[36,6],null,[[\"resource\",\"value\",\"editable\",\"hasMore\",\"loadMore\"],[\"employee\",[35,5],false,[35,4],[30,[36,3],[[32,0],\"loadMore\"],null]]]]],[13],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"campaign\",\"pluralize\",\"if\",\"action\",\"hasMore\",\"employeesIds\",\"array-input\",\"slide-over\"]}",
    "meta": {
      "moduleName": "riot/templates/campaign/index/audience.hbs"
    }
  });

  _exports.default = _default;
});