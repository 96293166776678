define("riot/helpers/pluralize", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pluralizer = pluralizer;
  _exports.default = void 0;

  function pluralizer(params) {
    var x = params[0];
    var word = params[1];

    if (x >= 2 || x === true || x === 0) {
      word = String(word).w().map(function (w) {
        if (w === 'is') {
          return 'are';
        }

        return (0, _emberInflector.pluralize)(w);
      }).join(' ');
    }

    return word;
  }

  var _default = Ember.Helper.helper(pluralizer);

  _exports.default = _default;
});