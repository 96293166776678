define("riot/helpers/date", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.date = date;
  _exports.default = void 0;

  function date(_ref, params) {
    var _ref2 = (0, _toArray2.default)(_ref),
        date = _ref2[0],
        args = _ref2.slice(1);

    var options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    if (params.date) {
      date = params.date;
    }

    if (params.seconds) {
      date = new Date(date * 1000);
    } // TODO: `params` should probably override `options`


    return date.toLocaleDateString(undefined, options);
  }

  var _default = Ember.Helper.helper(date);

  _exports.default = _default;
});