define("riot/templates/components/email-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kPVxkK6k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"window\"],[12],[10,\"div\"],[14,0,\"dot\"],[12],[13],[10,\"div\"],[14,0,\"dot\"],[12],[13],[10,\"div\"],[14,0,\"dot\"],[12],[13],[13],[6,[37,11],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,11],[[35,13,[\"subject\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"subject\"],[12],[10,\"div\"],[14,0,\"from\"],[12],[1,[34,7]],[2,\" \"],[10,\"small\"],[12],[1,[34,8]],[13],[13],[6,[37,11],[[30,[36,10],[[35,9,[\"template\",\"id\"]],\"raw-text\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"span\"],[14,\"contenteditable\",\"\"],[14,0,\"raw-subject\"],[12],[1,[34,6]],[13]],\"parameters\":[]},{\"statements\":[[1,[34,6]]],\"parameters\":[]}]]],[13],[10,\"div\"],[15,5,[34,12]],[14,0,\"email\"],[12],[10,\"iframe\"],[12],[13],[6,[37,11],[[35,3]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,5],null,[[\"fromTop\",\"selection\",\"value\",\"setVariable\"],[[35,4],[35,3],[35,2],[30,[36,1],[[32,0],\"setVariable\"],null]]]]]],\"parameters\":[]}]]],[13]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"loading\"],[12],[10,\"div\"],[14,0,\"from\"],[12],[13],[10,\"div\"],[14,0,\"subject\"],[12],[13],[10,\"div\"],[14,0,\"email\"],[12],[13],[13]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"empty-state\"],[12],[1,[30,[36,0],null,[[\"icon\"],[\"solid:collection\"]]]],[10,\"h4\"],[12],[2,\"No template selected\"],[13],[10,\"p\"],[12],[2,\"Select a template to preview the attack\"],[13],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hero-icon\",\"action\",\"value\",\"selection\",\"fromTop\",\"variable-popover\",\"subject\",\"fromName\",\"fromAddress\",\"preview\",\"is-eq\",\"if\",\"style\",\"email\"]}",
    "meta": {
      "moduleName": "riot/templates/components/email-preview.hbs"
    }
  });

  _exports.default = _default;
});