define("riot/templates/phishing/adp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XH/iS+wh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"phishing adp\"],[12],[11,\"form\"],[4,[38,2],[[32,0],\"login\"],[[\"target\",\"on\"],[[35,1],\"submit\"]]],[12],[10,\"h3\"],[12],[2,\"Log in to ADP\"],[13],[10,\"p\"],[14,0,\"input-label\"],[12],[2,\"USER ID\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"class\",\"value\",\"disabled\"],[\"mail\",[35,1,[\"email\"]],true]]]],[13],[10,\"p\"],[14,0,\"input-label\"],[12],[2,\"PASSWORD\"],[13],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"class\",\"value\",\"type\",\"autofocus\"],[\"password\",[35,1,[\"password\"]],\"password\",true]]]],[13],[6,[37,4],[[35,1,[\"hacked\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"severity\"],[\"Service temporary down\",\"danger\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"The service is currently under maintenance. Please try again later.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[1,[30,[36,5],null,[[\"label\"],[\"Remember User ID\"]]]],[1,[30,[36,6],null,[[\"label\",\"loading\"],[\"LOG IN\",[35,1,[\"isLoading\"]]]]]],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"parentController\",\"action\",\"input\",\"if\",\"checkbox-input\",\"smart-button\"]}",
    "meta": {
      "moduleName": "riot/templates/phishing/adp.hbs"
    }
  });

  _exports.default = _default;
});