define("riot/components/on-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['on-chat'],
    currentUser: Ember.inject.service(),
    intercom: Ember.inject.service(),
    api: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    messages: [],
    upcoming: null,
    // Needs to be setup when creatin the component
    init: function init() {
      this._super.apply(this, arguments);

      this.send('next');
    },
    actions: {
      next: function next() {
        // It's safer to `copy` here
        var message = this.upcoming.shift();

        if (message) {
          message = Ember.Object.create(message);

          if (message.get('type') === 'clear') {
            var delay = message.get('delay') || 1;
            Ember.run.later(this, function () {
              this.set('messages', []);
              this.send('next');
            }, delay * 1000);
          } else {
            if (message.get('callback')) {
              message.set('callback', Ember.run.bind(this, message.get('callback')));
            }

            this.messages.addObject(message);
          }
        }
      }
    }
  });

  _exports.default = _default;
});