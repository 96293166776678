define("riot/components/campaign-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['statistics'],
    store: Ember.inject.service(),
    date: Ember.computed('campaign.company.id', 'campaign.statistics.current.attacks.sent', 'campaign.{id,nextAttack,status}', function () {
      var _this = this;

      var status = this.get('campaign.status');

      if (status === 'completed') {
        return this.store.query('activity', {
          query: function query(ref) {
            return ref.where('campaign', '==', _this.get('campaign.id')).where('company', '==', _this.get('campaign.company.id')).where('type', '==', 'campaign-status-changed').where('changes.after.status', '==', 'completed').orderBy('createdAt', 'desc');
          }
        });
      }

      if (status === 'running') {
        return this.get('campaign.nextAttack') || 'today';
      }
    })
  });

  _exports.default = _default;
});