define("riot/templates/employee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/R8R9kN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"title\",\"breadcrumb\",\"tags\"],[[35,1,[\"_name\"]],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],null,[[\"color\",\"link\",\"label\",\"icon\"],[\"gray\",\"employee.edit\",\"Edit employee\",\"pencil\"]]]],[1,[30,[36,0],null,[[\"color\",\"action\",\"resource\",\"label\",\"icon\"],[\"purple\",[30,[36,2],[[32,0],\"newAttack\"],null],[35,1],\"Create a new attack\",\"sparkles\"]]]]],\"parameters\":[]}]]],[1,[30,[36,6],null,[[\"employee\"],[[35,1]]]]],[10,\"main\"],[12],[1,[30,[36,7],null,[[\"employee\"],[[35,1]]]]],[13],[1,[30,[36,9],[[30,[36,8],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"smart-button\",\"employee\",\"action\",\"tags\",\"breadcrumb\",\"header-bar\",\"employee-statistics\",\"attacks-table\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "riot/templates/employee.hbs"
    }
  });

  _exports.default = _default;
});