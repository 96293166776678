define("riot/templates/group/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WpFjuPHv",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"close\"],[[30,[36,0],[[32,0],\"close\"],null]]],[[\"default\"],[{\"statements\":[[11,\"form\"],[4,[38,0],[[32,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[10,\"section\"],[12],[10,\"h3\"],[12],[2,\"Edit \"],[1,[35,1,[\"name\"]]],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Name\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,2],null,[[\"value\",\"autofocus\"],[[35,1,[\"name\"]],true]]]],[13],[13],[10,\"label\"],[14,\"for\",\"\"],[12],[10,\"span\"],[12],[2,\"Employees\"],[13],[1,[30,[36,3],null,[[\"resource\",\"value\",\"action\"],[\"employee\",[35,1,[\"employees\"]],[30,[36,0],[[32,0],\"employeesChanged\"],null]]]]],[13],[13],[10,\"section\"],[14,0,\"actions\"],[12],[10,\"div\"],[14,0,\"left\"],[12],[1,[30,[36,5],null,[[\"color\",\"type\",\"label\",\"action\",\"loading\"],[\"red\",\"button\",\"Delete\",[30,[36,0],[[32,0],\"delete\"],null],[35,4]]]]],[13],[1,[30,[36,5],null,[[\"color\",\"label\",\"loading\"],[\"purple\",\"Save\",[35,4]]]]],[13],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"group\",\"input\",\"array-input\",\"isLoading\",\"smart-button\",\"slide-over\"]}",
    "meta": {
      "moduleName": "riot/templates/group/edit.hbs"
    }
  });

  _exports.default = _default;
});