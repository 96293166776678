define("riot/templates/components/callout-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZN4I++rI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"icon\"],[12],[1,[30,[36,2],null,[[\"icon\"],[[35,1]]]]],[13]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"content\"],[12],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"h5\"],[12],[1,[34,0]],[13]],\"parameters\":[]}]]],[18,1,null],[13],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"icon\",\"hero-icon\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/components/callout-message.hbs"
    }
  });

  _exports.default = _default;
});