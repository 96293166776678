define("riot/templates/components/algolia-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y0N+vTkl",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,13],null,[[\"isLoading\",\"value\",\"autofocus\",\"icon\",\"name\",\"placeholder\",\"search\",\"select\",\"create\",\"disabled\",\"items\"],[[35,12],[35,11],[35,10],\"solid:search\",[35,9],[35,8],[30,[36,7],[[32,0],\"search\"],null],[30,[36,7],[[32,0],\"select\"],null],[30,[36,7],[[32,0],\"create\"],null],[30,[36,6],[[35,5]],null],[35,4]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],\"employee\"],null]],null,[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]],[10,\"small\"],[12],[1,[32,1,[\"email\"]]],[13]],\"parameters\":[]}]]],[6,[37,3],[[30,[36,2],[[35,1],\"group\"],null]],null,[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]],[10,\"small\"],[12],[1,[32,1,[\"employeesCount\"]]],[2,\" \"],[1,[30,[36,0],[[32,1,[\"employeesCount\"]],\"employee\"],null]],[13]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"pluralize\",\"resource\",\"is-eq\",\"if\",\"items\",\"isLoaded\",\"not\",\"action\",\"placeholder\",\"name\",\"autofocus\",\"value\",\"isLoading\",\"autocomplete-input\"]}",
    "meta": {
      "moduleName": "riot/templates/components/algolia-input.hbs"
    }
  });

  _exports.default = _default;
});