define("riot/controllers/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    api: Ember.inject.service(),
    actions: {
      tryOut: function tryOut() {
        this.api.completeSetup(this.get('currentUser.company.id'));
        this.notify.create("A **confirmation email** has been sent", 'confirmation');
      }
    }
  });

  _exports.default = _default;
});