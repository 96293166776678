define("riot/components/campaign-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'main',
    classNames: ['campaign-setting'],
    classNameBindings: ['isExpanded:expanded:collapsed'],
    actions: {
      save: function save() {
        this.send('toggle');

        if (this.action) {
          return this.action();
        }
      },
      toggle: function toggle() {
        this.toggleProperty('isExpanded');
      },
      expand: function expand() {
        if (!this.isExpanded) {
          this.send('toggle');
        }
      }
    }
  });

  _exports.default = _default;
});