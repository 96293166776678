define("riot/components/modal-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modal-panel'],
    onRender: Ember.on('didInsertElement', function () {
      $('body').addClass('with-modal-panel');
    }),
    onClose: Ember.on('willDestroyElement', function () {
      $('body').removeClass('with-modal-panel');
    }),
    keyDown: function keyDown(e) {
      if (e.which === 27) {
        // ESC key
        this.send('close');
      }
    },
    click: function click(e) {
      if ($(e.target).hasClass('overlay')) {
        this.send('close');
      }
    },
    actions: {
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});