define("riot/templates/components/statistics-waterfall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zR8cVlAh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[12],[10,\"p\"],[12],[1,[30,[36,1],[[35,0,[\"attacks\"]],\"Attack\"],null]],[2,\" sent\"],[13],[10,\"strong\"],[14,0,\"large\"],[12],[1,[35,0,[\"attacks\"]]],[13],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"badge blue\"],[12],[1,[35,0,[\"attacksVsVulnerabilities\"]]],[2,\"%\"],[13]],\"parameters\":[]}]]],[13],[10,\"li\"],[12],[10,\"p\"],[12],[2,\"New \"],[1,[30,[36,1],[[35,0,[\"vulnerabilities\"]],\"vulnerability\"],null]],[13],[10,\"strong\"],[14,0,\"large\"],[12],[1,[35,0,[\"vulnerabilities\"]]],[13],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"badge blue\"],[12],[1,[35,0,[\"vulnerabilitiesVsTrainings\"]]],[2,\"%\"],[13]],\"parameters\":[]}]]],[13],[10,\"li\"],[12],[10,\"p\"],[12],[1,[30,[36,1],[[35,0,[\"trainings\"]],\"Training\"],null]],[2,\" done\"],[13],[10,\"strong\"],[14,0,\"large\"],[12],[1,[35,0,[\"trainings\"]]],[13],[13]],\"hasEval\":false,\"upvars\":[\"statistics\",\"pluralize\",\"percentage\",\"if\"]}",
    "meta": {
      "moduleName": "riot/templates/components/statistics-waterfall.hbs"
    }
  });

  _exports.default = _default;
});