define("riot/controllers/employees/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    parentController: Ember.inject.controller('employees'),
    queryParams: ['keyword', 'groups'],
    appendEmail: Ember.computed('currentUser.company.domain', function () {
      return "@".concat(this.get('currentUser.company.domain'));
    }),
    valid: Ember.computed('email', function () {
      var regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      return regex.test(this.email);
    }),
    actions: {
      new: function _new() {
        if (this.valid) {
          var groups = this.groupsArray || [];
          this.set('isLoading', true);
          this.parentController.new(this.getProperties('name', 'email'), false, groups);
        }
      },
      close: function close() {
        this.transitionToRoute('employees');
      }
    }
  });

  _exports.default = _default;
});