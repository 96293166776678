define("riot/templates/attack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HmaiVHOI",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"title\",\"breadcrumb\"],[[35,3],[35,2]]]]],[10,\"main\"],[12],[10,\"section\"],[12],[10,\"h4\"],[12],[2,\"Recent activity\"],[13],[13],[6,[37,5],[[35,0,[\"isIncoming\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,1],null,[[\"attack\"],[[35,0]]]]]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"loader\"],[12],[2,\"Please wait…\"],[13]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"attack\",\"activity-feed\",\"breadcrumb\",\"title\",\"header-bar\",\"unless\"]}",
    "meta": {
      "moduleName": "riot/templates/attack.hbs"
    }
  });

  _exports.default = _default;
});