define("riot/templates/employees/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MsGnuQ4N",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,15],null,[[\"close\"],[[30,[36,1],[[32,0],\"close\"],null]]],[[\"default\"],[{\"statements\":[[11,\"form\"],[4,[38,1],[[32,0],\"new\"],[[\"on\"],[\"submit\"]]],[12],[10,\"section\"],[12],[10,\"h3\"],[12],[2,\"Add a new employee\"],[13],[6,[37,3],[[35,2,[\"company\",\"statistics\",\"current\",\"employees\",\"total\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"title\",\"icon\"],[\"You don't have any employee\",\"light-bulb\"]],[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[2,\"Try out Riot by \"],[10,\"strong\"],[12],[2,\"creating yourself\"],[13],[2,\" as the first employee.\"],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Name\"],[13],[10,\"div\"],[14,0,\"input\"],[12],[1,[30,[36,5],null,[[\"autofocus\",\"value\",\"name\",\"placeholder\"],[true,[35,4],\"name\",\"Optional\"]]]],[13],[13],[10,\"label\"],[12],[10,\"span\"],[12],[2,\"Email address\"],[13],[1,[30,[36,8],null,[[\"value\",\"name\",\"append\",\"placeholder\"],[[35,7],\"email\",[35,6],\"john.doe\"]]]],[13],[10,\"label\"],[14,\"for\",\"\"],[12],[10,\"span\"],[12],[2,\"Groups\"],[13],[1,[30,[36,10],null,[[\"resource\",\"value\"],[\"group\",[35,9]]]]],[13],[13],[10,\"section\"],[14,0,\"actions\"],[12],[1,[30,[36,14],null,[[\"color\",\"label\",\"loading\",\"disabled\"],[\"purple\",\"Create\",[35,13],[30,[36,12],[[35,11]],null]]]]],[13],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"callout-message\",\"action\",\"currentUser\",\"unless\",\"name\",\"input\",\"appendEmail\",\"email\",\"smart-input\",\"groupsArray\",\"array-input\",\"valid\",\"not\",\"isLoading\",\"smart-button\",\"slide-over\"]}",
    "meta": {
      "moduleName": "riot/templates/employees/new.hbs"
    }
  });

  _exports.default = _default;
});