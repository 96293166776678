define("riot/templates/components/modal-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "krkmTbJ7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"modal\"],[12],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,1],null,[[\"icon\",\"click\"],[\"solid:x\",[30,[36,0],[[32,0],\"close\"],null]]]]]],\"parameters\":[]}]]],[18,1,null],[13],[10,\"div\"],[14,0,\"overlay\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"hero-icon\",\"hideClose\",\"unless\"]}",
    "meta": {
      "moduleName": "riot/templates/components/modal-panel.hbs"
    }
  });

  _exports.default = _default;
});