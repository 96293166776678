define("riot/models/employee", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var colors = ['White', 'Yellow', 'Blue', 'Red', 'Green', 'Black', 'Brown', 'Azure', 'Ivory', 'Teal', 'Silver', 'Purple', 'Navy blue', 'Pea green', 'Gray', 'Orange', 'Maroon', 'Charcoal', 'Aquamarine', 'Coral', 'Fuchsia', 'Wheat', 'Lime', 'Crimson', 'Khaki', 'Hot pink', 'Magenta', 'Olden', 'Plum', 'Olive', 'Cyan'];
  var words = ['alligator', 'ant', 'bear', 'bee', 'bird', 'camel', 'cat', 'cheetah', 'chicken', 'chimpanzee', 'cow', 'crocodile', 'deer', 'dog', 'dolphin', 'duck', 'eagle', 'elephant', 'fish', 'fly', 'fox', 'frog', 'giraffe', 'goat', 'goldfish', 'hamster', 'hippopotamus', 'horse', 'kangaroo', 'kitten', 'lion', 'lobster', 'monkey', 'octopus', 'owl', 'panda', 'pig', 'puppy', 'rabbit', 'rat', 'scorpion', 'seal', 'shark', 'sheep', 'snail', 'snake', 'spider', 'squirrel', 'tiger', 'turtle', 'wolf', 'zebra'];

  var _default = _model.default.extend({
    // read only
    createdAt: (0, _model.attr)('timestamp'),
    creator: (0, _model.attr)('string'),
    company: (0, _model.belongsTo)('company'),
    email: (0, _model.attr)('string'),
    lastAttack: (0, _model.attr)(),
    lastTraining: (0, _model.attr)(),
    statistics: (0, _model.attr)(),
    // write
    name: (0, _model.attr)('string'),
    health: (0, _model.attr)('string', {
      defaultValue: 'unknown'
    }),
    status: (0, _model.attr)('string'),
    currentUser: Ember.inject.service(),
    companyCampaigns: Ember.computed('company.id', 'store', function () {
      var _this = this;

      return this.store.query('campaign', {
        query: function query(ref) {
          return ref.where('company', '==', _this.get('company.id')).where('recipients.company', '==', _this.get('company.id')).where('status', '==', 'running');
        }
      });
    }),
    employeeCampaigns: Ember.computed('company.id', 'id', 'store', function () {
      var _this2 = this;

      return this.store.query('campaign', {
        query: function query(ref) {
          return ref.where('company', '==', _this2.get('company.id')).where('recipients.employees', 'array-contains', _this2.id).where('status', '==', 'running');
        }
      });
    }),
    groupsCampaigns: Ember.computed.map('groupsIds.length', ['company.id'], function (groupId) {
      var _this3 = this;

      return this.store.query('campaign', {
        query: function query(ref) {
          return ref.where('company', '==', _this3.get('company.id')).where('recipients.groups', 'array-contains', groupId).where('status', '==', 'running');
        }
      });
    }),
    campaigns: Ember.computed('groupsCampaigns.@each.length', 'employeeCampaigns.length', 'companyCampaigns.length', function () {
      var campaigns = [];
      this.groupsCampaigns.forEach(function (groupCampaigns) {
        return campaigns.addObjects(groupCampaigns);
      });
      campaigns.addObjects(this.employeeCampaigns);
      campaigns.addObjects(this.companyCampaigns);
      return campaigns;
    }),
    _name: Ember.computed('name', 'email', 'currentUser.company.settings.obfuscate', function () {
      var name = this.name;

      if (!Ember.isEmpty(this.email)) {
        if (!name || Ember.isEmpty(name.trim())) {
          return this.email.split('@').shift().capitalize();
        } else if (this.get('currentUser.company.settings.obfuscate')) {
          var color = colors[name.charCodeAt(0) * name.length % colors.length];
          var word = words[name.charCodeAt(0) * name.length % words.length];
          return "".concat(color, " ").concat(word);
        }
      }

      return name;
    }),
    groupsIds: Ember.computed.mapBy('groups', 'id'),
    groups: Ember.computed('currentUser.company.id', 'id', 'store', function () {
      var _this4 = this;

      if (this.get('currentUser.company.id') && this.id) {
        return this.store.query('group', {
          query: function query(ref) {
            return ref.where('company', '==', _this4.get('currentUser.company.id')).where('employees', 'array-contains', _this4.id);
          }
        });
      } else {
        return [];
      }
    })
  });

  _exports.default = _default;
});