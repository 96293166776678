define("riot/templates/components/campaign-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W0qRlnmc",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"frequency\",\"type\",\"save\",\"isExpanded\"],[[35,2,[\"frequency\"]],[35,2,[\"type\"]],[30,[36,1],[[32,0],\"save\"],null],[35,0]]]]],[1,[30,[36,5],null,[[\"value\",\"save\",\"isExpanded\"],[[35,2,[\"recipients\"]],[30,[36,1],[[32,0],\"save\"],null],[35,4]]]]],[1,[30,[36,7],null,[[\"value\",\"audience\",\"save\",\"isExpanded\"],[[35,2,[\"templates\"]],[35,2,[\"audience\"]],[30,[36,1],[[32,0],\"save\"],null],[35,6]]]]],[1,[30,[36,9],null,[[\"value\",\"save\",\"isExpanded\"],[[35,2,[\"autoSendTraining\"]],[30,[36,1],[[32,0],\"save\"],null],[35,8]]]]]],\"hasEval\":false,\"upvars\":[\"isFrequencyExpanded\",\"action\",\"value\",\"campaign-frequency-setting\",\"isRecipientsExpanded\",\"campaign-recipients-setting\",\"isTemplatesExpanded\",\"campaign-templates-setting\",\"isTrainingExpanded\",\"campaign-training-setting\"]}",
    "meta": {
      "moduleName": "riot/templates/components/campaign-editor.hbs"
    }
  });

  _exports.default = _default;
});